import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logo from './../Images/TCG-LADDER-LOGO.png';
import { Layout } from './Layout';
import ladder from './../Images/ladder.png';
import matchhistory from './../Images/matchhistory.png';
import playgroup from './../Images/playgroup.png';
import stats from './../Images/stats.png';

class LandingPage extends React.Component {
    render() {
        return (
            <Layout showNavMenu={false}>
            <Container className="text-center">
                <Row>
                    <Col>
                        <img style={{width:"300px"}} src={Logo}></img>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col style={{textAlign:"right"}}>
                     <a href="/login" className="btn btn-primary">Sign in</a>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                            <p style={{ lineHeight: "2.3em" } }>
                            TCGLadder is a mobile first, responsive web application that adjusts to your device, meant for tracking your playgroup's real-life games. TCGladder is game agnostic, and will be able to show you your match history, allow you to run granular reports on both Players within your playgroup and their individual decks, and give you the ability to create seasonal and All-Time ranking ladders for your playgroup!
                        </p>
                    </Col>
                </Row>
                <Row>
                        <Col style={{ lineHeight: "2.3em" }}>
                         Please join the community discord <a target="_blank" href="https://discord.gg/d94ybkNbsN">here</a> if you would like to follow development, report bugs, and submit feature requests.
                    </Col>
                    </Row>
                    <Row>
                        <Col style={{ lineHeight: "2.3em" }}>
                            Please join the subreddit <a target="_blank" href="https://www.reddit.com/r/tcgladder/">here</a>
                        </Col>
                    </Row>
                <br />
                <Row>
                    <Col>
                        <h2>Feature Highlights</h2>
                    </Col>
                </Row>
                    <br />
                    <Row className="container-box">
                    <Col>
                        <h4>Create your personalized playgroups</h4>
                            <b style={{ lineHeight: "2.3em" }}>Invite your friends to your personal playgroup, and create/join matches for the TCG of your choice! Creating your personalized playgroup allows you to build a unique space where you and your friends can come together. Gather your fellow enthusiasts, create, and join matches to find out who is really the best!</b>
                    </Col>
                    <Col>
                            <img style={{ width: "40%" }} src={playgroup} alt="Image Placeholder" />
                    </Col>
                </Row>
               <br />
                    <Row className="container-box">
                    <Col>
                            <h4>Customizable Reports on demand</h4>
                            <b style={{ lineHeight: "2.3em" }}>Run reports on your playgroup effortlessly and gain insights into player and deck performance. Whether you're looking to refine your strategies or celebrate your victories, our reporting tools make it easy to track the pulse of your playgroup and elevate your gaming experience.</b>
                    </Col>
                    <Col>
                            <img src={stats} style={{ width: "80%" }} alt="Image Placeholder" />
                    </Col>
                </Row>
               <br />
                    <Row className="container-box">
                    <Col>
                        <h4>Dynamic ladders</h4>
                            <b style={{ lineHeight: "2.3em" }}> Create dynamic ladders that add a thrilling competitive edge to your playgroup. Climb your playgroup's ladder, which can be tailored for all-time ranking or seasonally refreshed. Our ladders dynamically recalculate based on your chosen metric, ensuring a challenging and engaging experience for all players.</b>
                    </Col>
                    <Col>
                            <img style={{ width: "40%" }} src={ladder} alt="Image Placeholder" />
                    </Col>
                </Row>
             <br />
                    <Row className="container-box">
                    <Col>
                        <h4>View your match history</h4>
                            <b style={{ lineHeight: "2.3em" }}> Discover your gaming journey with match history. Delve into the intricacies of your playgroup's matches. Peek into the notes and highlights, shared by you and fellow players, for a richer perspective on your gameplay.</b>
                    </Col>
                    <Col>
                            <img style={{ width: "50%" }} src={matchhistory} alt="Image Placeholder" />
                    </Col>
                </Row>
                </Container>
            </Layout>
        );
    }
}

export default LandingPage;
