import React, { Component } from "react";
import { getApiData, postApiData } from "./../fetchApi";

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
        };
    }

    checkout = async () => {
        var url = await postApiData("PERFORM_CHECKOUT", [sessionStorage.getItem("x-params")]);
        window.location.href = url
    };

  

    render() {
        const { message } = this.state;

        return message ? (
            <Message message={message} />
        ) : (
            <ProductDisplay checkout={this.checkout} />
        );
    }
}

class ProductDisplay extends Component {
    render() {
        return (
            <section>
                <button className="btn btn-primary" onClick={this.props.checkout} type="submit">
                    Sign up!
                </button>
            </section>
        );
    }
}

class Message extends Component {
    render() {
        return (
            <section>
                <p>{this.props.message}</p>
            </section>
        );
    }
}

export default CheckoutForm;
