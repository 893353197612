import React, { Component } from 'react';
import { Input, Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { getApiData, postApiData } from './../fetchApi';
import Playgroup from './PlayGroup';
import Match from './Match';
import { Layout } from './Layout';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playgroups: [],
            matchId: 0,
            errMsg: "",
            errMsgJoinGame: "",
            selectedDeckId: 0,
            selectedDeckName: "",
            decks: [],
            playgroupName: "",
            activeMatchId: 0,
            hostAccountId: 0,
            matchName: "",
            refresh: false,
            key: 0,
            matchStarted: false,
            matchKey: 0,
            createMatchErr: "",
            decks: [],
            selectedGameTypeId: 0,
            selectedGameTypeName: "",
            playGroupNameErr: "",
            isMatchStarted: 0

        };
    }

    componentDidMount = async () => {
        var playgroups = await getApiData("GET_PLAYGROUPS", [sessionStorage.getItem('x-params')]);
        var onGoingMatchObj = await getApiData("GET_ONGOING_MATCH", [sessionStorage.getItem('x-params')])
        this.setState({ playgroups: playgroups, activeMatchId: onGoingMatchObj.ongoingMatchId, hostAccountId: onGoingMatchObj.hostId, isMatchStarted: onGoingMatchObj.isMatchStarted, selectedDeckId: onGoingMatchObj.selectedDeckId });
    }

    addPlayerToMatch = async (matchId) => {
        var isSuccess = await postApiData("ADD_PLAYER_TO_MATCH", [sessionStorage.getItem('x-params'), matchId])

        if (isSuccess)
            this.setState({ matchId: matchId })
        else
            this.setState({ errMsg: "Unable to join the match." })
    }

    handleDeckSelection = (deckId, deckName) => {
        this.setState({ selectedDeckId: deckId, selectedDeckName: deckName });
    }
    handleGameTypeSelection = async (gameTypeId) => {
        var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), gameTypeId]);
        this.setState({ selectedGameTypeId: gameTypeId, decks: decks, selectedDeckId: 0 });
    }
     

    createPlaygroup = async () => {
        if (this.state.playgroupName.trim() === '')
            this.setState({ playGroupNameErr: "Playgroup name required" })
        else { 
            var createPlaygroupObj = { guid: sessionStorage.getItem('x-params'), playgroupname: this.state.playgroupName }
            var result = await postApiData("CREATE_PLAYGROUP", [createPlaygroupObj])
            switch (result) {
                case "Premium Max":
                    this.setState({ playGroupNameErr: "Premium accounts are capped at 12 playgroups." })
                    break;
                case "Freemium Max":
                    this.setState({ playGroupNameErr: "Free accounts are capped at 4 playgroups. Please consider getting premium to increase the cap to 12." })
                    break;
                case "Success":
                    var playgroups = await getApiData("GET_PLAYGROUPS", [sessionStorage.getItem('x-params')]);
                    this.setState({ playgroups: playgroups, playGroupNameErr: "", playgroupName: "" });
            }
        }
    }

    handlePlaygroupNameChange = (e) => {
        this.setState({playgroupName: e.target.value})
    } 

    handlePlaygroupDelete = async (id) => {
        await postApiData("DELETE_PLAYGROUP", [id])
        this.handlePlaygroupRefresh();
    }

    handleLeaveClick = async (id, newhostId) => {
        var leaveObj = { guid: sessionStorage.getItem('x-params'), playgroupId: id, newhostId: newhostId }
        await postApiData("LEAVE_PLAYGROUP", [leaveObj])
        this.handlePlaygroupRefresh();
    }

    handleLeaveUserClick = async (id) => {
        var leaveObj = { guid: sessionStorage.getItem('x-params'), playgroupId: id}
        await postApiData("LEAVE_PLAYGROUP_USER", [leaveObj])
        this.handlePlaygroupRefresh();
    }

    hostNewMatch = async (hostAccountId, matchName, playgroupId) => {
        var canHost = await getApiData("CHECK_HOST_PERMISSIONS", [hostAccountId, playgroupId])
        if (canHost) {
            var newMatchObj = {
                matchname: matchName, deckidlist: this.state.selectedDeckId.toString(), status: "Pending", accountidlist: hostAccountId.toString(), playgroupid: playgroupId.toString(), gametypeid: this.state.selectedGameTypeId.toString(), timezone: sessionStorage.getItem("userTimeZone")}
            var matchId = await postApiData("CREATE_NEW_MATCH", [newMatchObj])
            this.setState({ activeMatchId: matchId.id, hostAccountId: hostAccountId, matchName: matchName, createMatchErr:"" })
        }
        this.setState({ createMatchErr: "You do not have permission to create a match" })
       
    } 

    joinMatch = async (matchId, accountId) =>
    {
        var joinMatchObj = { matchid: matchId.toString(), deckid: this.state.selectedDeckId.toString(), accountid: accountId.toString() }
        var result = await postApiData("JOIN_MATCH", [joinMatchObj])
        if (result === "Error")
            this.setState({ errMsgJoinGame: "Match already in progress" })
        if (result === "Full")
            this.setState({ errMsgJoinGame: "The match is full." })
        if (result === "Joined")
            this.setState({ activeMatchId: matchId, errMsgJoinGame: ""  })
    }

    handlePlaygroupRefresh = async () => {
        var playgroups = await getApiData("GET_PLAYGROUPS", [sessionStorage.getItem('x-params')]);
        this.setState({ key: this.state.key + 1, errMsgJoinGame: "", playgroups: playgroups, playGroupNameErr: "" });
    }

    setRematch = async (rematchId, selectedDeckId) => {
        this.setState({ activeMatchId: rematchId, selectedDeckId: selectedDeckId, matchKey: this.state.matchKey + 1, isMatchStarted: 0 })
    }


    render() {
        return (
            <>
                {this.state.activeMatchId === 0 ?
                    <Layout showNavMenu={true}>
                        <Container>
                            {this.state.playgroups.length === 0 ? (
                                <div>
                                    <h3>Welcome to TCG Ladder!</h3>
                                    <p>To get started, please create a play group and upload your decks.</p>
                                    <div class="row">
                                        <div style={{ marginBottom: "8px" }} class="col-md-4"><Input type="text" value={this.state.playgroupName} onChange={this.handlePlaygroupNameChange} placeholder="Playgroup Name"></Input></div>
                                        <div style={{ color: "red" }}>{this.state.playGroupNameErr}</div>
                                        <div class="col-md-2"><Button onClick={this.createPlaygroup} color="primary">Create Playgroup</Button></div> 
                                        <Row style={{marginTop:"10px"}}>
                                            <div className="col-sm-3"><Button className="btn btn-warning" onClick={this.handlePlaygroupRefresh}>Refresh Playgroups</Button></div>
                                        </Row> 
                                    </div>
                                </div>
                            ) : (
                                <div>
                                        <h4>Playgroup(s)</h4> <br />
                                        <div class="row">
                                            <div style={{ color: "red" }}>{this.state.playGroupNameErr}</div>
                                            <span><b>Create another playgroup</b></span>
                                            <div style={{ marginBottom: "8px" }} class="col-md-4"><Input type="text" maxLength={30} value={this.state.playgroupName} onChange={this.handlePlaygroupNameChange} placeholder="Playgroup Name"></Input></div>
                                            
                                            <div class="col-md-2"><Button onClick={this.createPlaygroup} color="primary">Create Playgroup</Button></div>
                                        </div> <br />
                                        <Row>
                                            <div className="col-sm-3"><Button className="btn btn-warning" onClick={this.handlePlaygroupRefresh}>Refresh Playgroups</Button></div>
                                        </Row> <br />
                                        <Row>
                                        
                                        {this.state.playgroups.map((playgroup, index) => (
                                            <Col key={index} sm="12" md="6" lg="4" xl="3">
                                                <Playgroup handlePlaygroupRefresh={this.handlePlaygroupRefresh} handleLeaveClick={this.handleLeaveClick} handleLeaveUserClick={this.handleLeaveUserClick} handlePlaygroupDelete={this.handlePlaygroupDelete} decks={this.state.decks} createMatchErr={this.state.createMatchErr} errMsgJoinGame={this.state.errMsgJoinGame} key={this.state.key + index} joinMatch={this.joinMatch} hostNewMatch={this.hostNewMatch} addPlayerToMatch={this.addPlayerToMatch} handleDeckSelection={this.handleDeckSelection} handleGameTypeSelection={this.handleGameTypeSelection} playgroupObj={playgroup} />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Layout>

                    :

                    <Match key={this.state.matchKey} isMatchStarted={this.state.isMatchStarted}  selectedDeckId={this.state.selectedDeckId} rematchcounter={this.state.matchKey} selectedGameTypeId={this.state.selectedGameTypeId} hostAccountId={this.state.hostAccountId} setRematch={this.setRematch} matchName={this.state.matchName} activeMatchId={this.state.activeMatchId}></Match>
                }
            </>
        );
    }
}

export default Home;
