import React, { Component } from 'react';
import { Layout } from './Layout';

class PlaygroupGuide extends Component {
    render() {
        return (
            <Layout showNavMenu={true}>
            <div>
                {/* First Row */}
                <div>
                    <h3>Create a Playgroup</h3>
                    <p>
                            A playgroup represents a collection of you and everyone you add to your playgroup. By default, only the host of the playgroup can create matches within a playgroup. 
                            You can add players to your playgroup by inputting their user ID and pressing "Add player to playgroup".  In your playgroup's Player List, you can assign a Mod role to anyone in your playgroup, which will allow them to also be able to host matches.
                    </p>
                </div>

               <br />
                       
               
                {/* Header - Create your decks */}
                <div>
                    <h3>Create/Modify/Archive Your Decks</h3>
                    <p>
                        After creating a playgroup, navigate to the "Manage Decks" menu. From here, you can Create, Modify, or Archive your decks. First, select a game type from the drop-down menu. You can now add decks under that specific game type. A deck only needs a name and some optional notes, but you do not need to import any cards.
                            After creating some decks, you can now modify them or archive/unarchive them. Keep in mind, you will only see the decks for your selected game type.
                        </p>
                </div>

                <br />

              

                {/* Next Row */}
                <div>
                    <h3>Create a Match</h3>
                    <p>
                        To create a match, first make sure you are either the host or a mod of a playgroup. Select a game type, and select a deck associated with that game type. Give your match a name and click "Create Match!"
                            You will be taken to the match lobby. From here, you can cancel or start the match. Please make sure that you see all the players in your lobby before starting.
                        </p>
                </div>

                <br />

                {/* Next Row */}
                <div>
                    <h3>Joining a Match</h3>
                    <p>
                        Anyone in your playgroup can now join your match. They need to select the game type that the match was created for and then select a corresponding deck. Press "Join Match" on the match you wish to join.
                    </p>
                </div>
                <br />
                {/* Header - Playing Your Game */}
                <div>
                    <h3>Playing Your Game</h3>
                    <p>
                        Now that all players are in the match, and the match has started, you can play your game in real life. During your match, each player can add notes for the match. Once the game ends, the host will be able to select from three options: "Rematch with the same decks," "Rematch with different decks," and "Return to playgroup selection."
                    </p>
                </div>

                <br />

                <div>
                    <p>
                        Once the host selects the winner and concludes the match, all players should click "Check for the next match." If a rematch with the same decks was checked, you will be brought into the new match lobby. If a rematch with different decks was checked, you will be given the option to swap decks before entering the rematch lobby. If "return to playgroup selection" was checked, you will be brought back to your playgroup's menu.
                    </p>
                </div>
                <br />
                {/* New Row */}
                <div>
                    <h3>View Your Match History</h3>
                    <p>
                        You can now view your entire match history. Navigate to the "Match History" menu option. First select the playgroup and the game type. Next, you can optionally filter by a date range and/or match name. Once you click "Get Matches," you will be shown your match history based on the inputted parameters. Your match history contains the match name, date, all the players in that match, all the decks in that match, and any additional notes that any player wrote for that match.
                        You will be shown upto 15 matches per page. You can swap pages using the page selector at the bottom of the screen.
                        </p>
                </div>
                <br />
                {/* New Row */}
                <div>
                    <h3>Run Statistics on Your Playgroup</h3>
                    <p>
                        Navigate to the "Statistics" menu option. You can view statistics on players or decks. Select a playgroup and a game type. You can optionally select a date range. By default, the date range is set to all time. You will be shown all the players in your playgroup. Check or uncheck each player to view/hide their statistics. You can run the following reports:
                        </p>
                    <p><b>Note: Canceled matches are not counted towards statistics</b></p>
                    <ul>
                        <li style={{padding:"10px"}}>Overall performance - This shows the overall performance of the selected players or decks in the selected playgroup for the selected game type.</li>
                        <li style={{ padding: "10px" }}>Overall performance in 1v1 - This shows the overall performance in 1v1 matches of the selected players or decks.</li>
                        <li style={{ padding: "10px" }}>Overall performance in Multiplayer - This shows the overall performance in multiplayer matches of the selected players or decks.</li>
                        <li style={{ padding: "10px" }}>Matched Exclusively - This shows the performance of the selected players or decks that played matches together exclusively. This report is useful for comparing specific player(s) or deck(s) against other specific player(s) or deck(s).</li>
                    </ul>
                </div>

                {/* Header - Create Ladders for Your Playgroup */}
                <div>
                    <h3>Create/View Ladders for Your Playgroup</h3>
                    <p>
                        Navigate to the "Ladders" menu item. Select a playgroup and a game type. You can only create ladders for playgroups you are the host of. Give your ladder a name and optionally select a date range. By default, the date range is set to all time. Finally, select the people in your playgroup to participate in this ladder.
                            You can view ladders for any playgroup you are a part of. Select a playgroup and a game type, and any existing ladders will generate with an empty shell. Inside this shell, you can select a metric. Once a metric is selected, the ladder will dynamically calculate on the fly and rank all the selected players based on the metric.
                        </p>
                        <p><b>Note: Canceled matches are not counted towards ladders</b></p>
                </div>

                <br />

               
                </div>
            </Layout>
        );
    }
}

export default PlaygroupGuide;
