import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Layout } from './Layout';
import { getApiData, postApiData } from './../fetchApi';

class DeckMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deckName: '',
            selectedPlayer: null,
            players: [], // Populate this from an API
            selectedColors: [],
            colorOptions: ['Red', 'Black', 'Blue', 'Green', 'White', 'Colorless'],
            dropdownOpen: false,
            dropdownOpenEdit: false,
            additionalNotes: "",
            decks: [],
            deckNameEdit: "",
            additionalNotesEdit: "",
            gameTypes: [],
            selectedGameType: [{ id: 0, gameType: "" }],
            selectedEditDeckId: 0,
            selectedArchiveOption: "Archive",
            selectedArchiveDeckId: 0,
            selectedArchiveDeckName: "",
            archiveErr: "",
            archiveDecks: []
        };
    }

    componentDidMount = async () => {
        var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), 0]);
        var gameTypes = await getApiData("GET_GAME_TYPES", [sessionStorage.getItem('x-params')]);
        var archiveDecks = await getApiData("GET_DECKS_FOR_ARCHIVE", [sessionStorage.getItem('x-params'), 0, this.state.selectedArchiveOption]);
        this.setState({ decks: decks, gameTypes: gameTypes, archiveDecks: archiveDecks })
    }

    // Handle form input changes
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };


    handleDeckSelectionEdit = (id) => {
        if (id !== 0) {
            var selectedDeck = this.state.decks.filter(x => x.id === id)
            this.setState({ selectedEditDeckId: id, deckNameEdit: selectedDeck[0].deckName, additionalNotesEdit: selectedDeck[0].additionalNotes })
        }
        else
            this.setState({ selectedEditDeckId: 0, deckNameEdit: "", additionalNotesEdit: "" })
       
    }

    handleDeckSelectionArchive = (id) =>
    {
        if (id !== 0) {
            var selectedDeck = this.state.archiveDecks.filter(x => x.id === id)
            this.setState({ selectedArchiveDeckId: id, selectedArchiveDeckName: selectedDeck[0].deckName})
        }
        else
            this.setState({ selectedArchiveDeckId: 0, selectedArchiveDeckName: ""})
    }


    handleGameTypeSelection = (id) => {
        var selectedGameType = 0
        if (id !== 0) {
            selectedGameType = this.state.gameTypes.filter(x => x.id === id)
            this.setState({ selectedGameType: selectedGameType, selectedEditDeckId: 0, selectedArchiveDeckId: 0, deckNameEdit: "", additionalNotesEdit: "" })
        }
        else
            this.setState({ selectedGameType: [{ id: 0, gameType: "" }], selectedEditDeckId: 0, selectedArchiveDeckId: 0, deckNameEdit: "", additionalNotesEdit: "" })
    }

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    handleCreateDeck = async (e) => {
        if (this.state.selectedGameType[0].id === 0 || this.state.deckName === '') {
            this.setState({createDeckErr:  "Game type & deck name required"})
        }
        else
        {
            var doesExist = this.state.decks.some(deck => deck.deckName === this.state.deckName);
            if (doesExist)
                this.setState({ createDeckErr: "Deck name already exists" })
            else {
                var deckObj = { deckName: this.state.deckName, guid: sessionStorage.getItem("x-params"), selectedGameTypeId: this.state.selectedGameType[0].id.toString(), additionalNotes: this.state.additionalNotes }
                await postApiData("SAVE_NEW_DECK", [deckObj])
                var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), 0]);
                this.setState({ decks: decks, deckName: "", additionalNotes: "", createDeckErr:"" })
            }
        }
        
    }

    handleModifyDeck = async (e) => {
        var deckObj = { deckId: this.state.selectedEditDeckId.toString(), deckName: this.state.deckNameEdit, additionalNotes: this.state.additionalNotesEdit }
        await postApiData("MODIFY_DECK", [deckObj])
        var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), 0]);
        this.setState({ decks: decks, deckNameEdit: "", additionalNotesEdit: "", selectedEditDeckId: 0})
    }

    handleArchiveDeck = async (e) => {
        if (this.state.selectedArchiveDeckId === 0)
            this.setState({archiveErr: "Deck selection required"})

        else {
            var deckObj = { deckId: this.state.selectedArchiveDeckId.toString(), archiveoption: this.state.selectedArchiveOption }
            await postApiData("ARCHIVE_DECK", [deckObj])
            var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), 0]);
            var archiveDecks = await getApiData("GET_DECKS_FOR_ARCHIVE", [sessionStorage.getItem('x-params'), 0, this.state.selectedArchiveOption]);
            this.setState({ decks: decks, selectedArchiveDeckId: 0, archiveDecks: archiveDecks })
        }
    }

    handleArchiveOption = async (option) => {
        var decks = await getApiData("GET_DECKS_FOR_ARCHIVE", [sessionStorage.getItem('x-params'), 0, option]);
        this.setState({ selectedArchiveOption: option, archiveDecks:decks })
       
    }

    render() {
        const { deckName,additionalNotes,deckNameEdit, additionalNotesEdit, selectedPlayer, players, selectedColors, colorOptions, dropdownOpen } = this.state;

        return (
            <Layout showNavMenu={true}>
                <div className="row">
                    <b>Select a game type to create/modify decks for that game</b>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown('dropdownOpen')}>
                        <DropdownToggle caret>
                            {this.state.selectedGameType[0].id !== 0 ? this.state.selectedGameType[0].gameType : 'Select Game Type'}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                key="Select Game Type"
                                onClick={() => this.handleGameTypeSelection(0)}
                            >
                                Select Game Type
                            </DropdownItem>
                            {this.state.gameTypes.map((gameType) => (
                                <DropdownItem
                                    key={gameType.id}
                                    onClick={() => this.handleGameTypeSelection(gameType.id)}
                                >
                                    {gameType.gameType}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown> <br />
                </div>
             <div className="row">
                    <div style={{ paddingBottom: "30px", paddingTop: "15px" }} className="col-lg-4">
                    <h3>Create</h3> <br />
                    <Form>
                        <FormGroup>
                            <Label for="deckName"><b>Deck Name</b></Label>
                            <Input type="text" maxLength={30} name="deckName" id="deckName" value={deckName} onChange={this.handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="deckName"><b>Additional Notes</b></Label>
                                <Input type="textarea" maxLength={50} name="additionalNotes" id="additionalNotes" value={additionalNotes} onChange={this.handleInputChange} />
                            </FormGroup>
                            <div><span style={{ color: "red" }}>{this.state.createDeckErr}</span></div>
                        <Button onClick={this.handleCreateDeck} color="primary">
                            Save
                        </Button>
                    </Form>
                </div>
                    <div style={{ paddingBottom: "30px", paddingTop: "15px" }} className="col-lg-4">
                
                    <h3>Modify</h3> <br />
                    <Form>
                        <Dropdown isOpen={this.state.dropdownOpenEdit} toggle={() => this.toggleDropdown('dropdownOpenEdit')}>
                            <DropdownToggle caret>
                                {this.state.selectedEditDeckId !== 0 ? this.state.deckNameEdit : 'Select Deck'} 
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    key="Select Deck"
                                    onClick={() => this.handleDeckSelectionEdit(0)}
                                >
                                    Select Deck
                                </DropdownItem>
                                    {this.state.decks.filter(x => x.gameTypeId === this.state.selectedGameType[0].id).map((deck) => (
                                    <DropdownItem
                                        key={deck.id}
                                        onClick={() => this.handleDeckSelectionEdit(deck.id)}
                                    >
                                        {deck.deckName}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                            </Dropdown>
                        <br />
                        <FormGroup>
                            <Label for="deckName"><b>Edit Deck Name</b></Label>
                            <Input type="text" name="deckNameEdit" id="deckNameEdit" value={deckNameEdit} onChange={this.handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="deckName"><b>Edit Additional Notes</b></Label>
                            <Input type="textarea" name="additionalNotesEdit" id="additionalNotesEdit" value={additionalNotesEdit} onChange={this.handleInputChange} />
                        </FormGroup>
                        <Button onClick={this.handleModifyDeck} color="danger">
                            Modify
                        </Button>
                    </Form>
                    </div>
                
                
                    <div style={{ paddingBottom: "30px", paddingTop: "15px" }} className="col-lg-4">

                    <h3>Archive/Unarchive</h3> <br />
                    <Form>
                        <p><b>Note:</b> Archived decks will not appear on your deck selection list. Their stats and match history will be retained. You can unarchive your deck at any time. </p><br />
                        <Dropdown isOpen={this.state.dropdownOpenArchiveOption} toggle={() => this.toggleDropdown('dropdownOpenArchiveOption')}>
                            <DropdownToggle caret>
                                {this.state.selectedArchiveOption}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    key="Archive"
                                    onClick={() => this.handleArchiveOption("Archive")}
                                >
                                    Archive
                                </DropdownItem>
                                <DropdownItem
                                    key="Unarchive"
                                    onClick={() => this.handleArchiveOption("Unarchive")}
                                >
                                    Unarchive
                                </DropdownItem>

                            </DropdownMenu>
                        </Dropdown>
                        <br />
                        <Dropdown isOpen={this.state.dropdownOpenArchive} toggle={() => this.toggleDropdown('dropdownOpenArchive')}>
                            <DropdownToggle caret>
                                {this.state.selectedArchiveDeckId !== 0 ? this.state.selectedArchiveDeckName : 'Select Deck'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    key="Select Deck"
                                    onClick={() => this.handleDeckSelectionArchive(0)}
                                >
                                    Select Deck
                                    </DropdownItem>
                                    {this.state.archiveDecks.filter(x => x.gameTypeId === this.state.selectedGameType[0].id).map((deck) => (
                                    <DropdownItem
                                        key={deck.id}
                                        onClick={() => this.handleDeckSelectionArchive(deck.id)}
                                    >
                                        {deck.deckName}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        
                        <br />
                       
                            <div style={{color:"red"} }>{this.state.archiveErr}</div>
                        <Button onClick={this.handleArchiveDeck} color="danger">
                                {this.state.selectedArchiveOption}
                        </Button>
                    </Form>
                </div>
            </div>
            </Layout>
        );
    }
}

export default DeckMenu;
