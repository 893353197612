import React, { Component } from 'react';
import { getApiData, postApiData } from '../fetchApi';
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';
import Chart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class DeckStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            players: this.props.playerList,
            generalReports: [],
            selectedMetric: null, // Track the selected metric for the pie chart
            metricSeries: [],
            chartOptions: {},
            dropdownOpen: false,
            dropdownReportType: false,
            metricTitle: "",
            selectedReport: "Overall performance",
            selectedPlayers: [],
            selectedStartDate: null,
            selectedEndDate: null,
        };
    }

    handleDateRangeChange = async (date) => {
        var selectedPlayers = this.state.selectedPlayers
        var start = date[0]
        var end = date[1]
        var deckIds = ''
        if (start && end) {
            if (this.state.selectedReport === "Matched exclusively") 
                deckIds = selectedPlayers.map(x => x.selectedDeckId).join(',');

            for (var x = 0; x < this.state.selectedPlayers.length; x++) {
                var apiData = await getApiData("GET_DECK_STATS", [selectedPlayers[x].selectedDeckId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, deckIds === '' ? "0" : deckIds, start.toISOString(), end.toISOString()]);
                selectedPlayers[x].selectedDeckData = apiData
            }

            this.setState({ selectedPlayers: selectedPlayers})

            if (this.state.selectedMetric) {
                this.handleMetricSelect(this.state.selectedMetric, this.state.metricTitle, selectedPlayers);
                // this.handleLineChartUpdate(this.state.selectedMetric, this.state.metricTitle);
            }

        }

        this.setState({ selectedStartDate: start, selectedEndDate: end });
    };

    handleCheckboxChange = async (player, e) => {
        var selectedPlayers = this.state.selectedPlayers
        var start = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : null
        var end = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : null
        if (e.target.checked) {
            var playerDecks = await getApiData("GET_DECKS_BY_ACCOUNTID", [player.accountId])
            var selectedPlayerObj = { player: player, decks: playerDecks, selectedDeckId: 0, selectedDeckName: "", selectedDeckData: [] }
            selectedPlayers.push(selectedPlayerObj)
        }

        else {
            if (this.state.selectedReport === "Matched exclusively") {
                selectedPlayers = selectedPlayers.filter(x => x.player.accountId.toString() !== player.accountId.toString())
                var deckIds = selectedPlayers.map(x => x.selectedDeckId).join(',');

                for (var x = 0; x < selectedPlayers.length; x++) {
                    var apiData = await getApiData("GET_DECK_STATS", [selectedPlayers[x].selectedDeckId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, deckIds === '' ? "0" : deckIds, start, end]);
                    selectedPlayers[x].selectedDeckData = apiData
                }
            }
            else
                selectedPlayers = selectedPlayers.filter(x => x.player.accountId.toString() !== player.accountId.toString())
        }
           
            
        this.setState({ selectedPlayers: selectedPlayers })
    };

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    handleMetricSelect = (selectedMetric, metricTitle, selectedPlayers = null) => {
        // Check if selectedMetric is not null and not an empty string
        if (selectedMetric && selectedMetric !== "") {
            // Initialize metricSeries and chartOptions
            let metricSeries = [];
            let chartOptions = {};

            if (selectedPlayers !== null) {
                // Map over the generalReports to extract the metric values
                metricSeries = selectedPlayers.map((playerObj) => {
                    let metricValue = playerObj.selectedDeckData[selectedMetric];
                    if (selectedMetric === 'Total Matches' || selectedMetric === 'Total Wins' || selectedMetric === 'Total Losses') {
                        // For numeric metrics, parse and aggregate
                        metricValue = parseInt(metricValue, 10); // Convert to integer
                        if (!isNaN(metricValue)) {
                            return metricValue;
                        }
                    } else {
                        // For percentage metrics, use as-is
                        return parseFloat(playerObj.selectedDeckData[selectedMetric]);
                    }
                    return 0; // Handle cases where the metric is not a valid number
                });

                // Populate chartOptions with labels and colors
                chartOptions = {
                    labels: selectedPlayers.map((playerObj) => playerObj.selectedDeckName), // Labels for each pie slice
                    colors : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FFD700', '#4CAF50', '#FF5733', '#A569BD', '#3498DB'] // Array of colors for each slice
                };
            }
            else {
                // Map over the generalReports to extract the metric values
                metricSeries = this.state.selectedPlayers.map((playerObj) => {
                    let metricValue = playerObj.selectedDeckData[selectedMetric];
                    if (selectedMetric === 'Total Matches' || selectedMetric === 'Total Wins' || selectedMetric === 'Total Losses') {
                        // For numeric metrics, parse and aggregate
                        metricValue = parseInt(metricValue, 10); // Convert to integer
                        if (!isNaN(metricValue)) {
                            return metricValue;
                        }
                    } else {
                        // For percentage metrics, use as-is
                        return parseFloat(playerObj.selectedDeckData[selectedMetric]);
                    }
                    return 0; // Handle cases where the metric is not a valid number
                });

                // Populate chartOptions with labels and colors
                chartOptions = {
                    labels: this.state.selectedPlayers.map((playerObj) => playerObj.selectedDeckName), // Labels for each pie slice
                    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FFD700', '#4CAF50', '#FF5733', '#A569BD', '#3498DB'], // Array of colors for each slice
                };
            }

            this.setState({ selectedMetric, metricSeries, chartOptions, metricTitle });
        } else {
            // Clear selectedMetric and metricSeries if an invalid metric is selected
            this.setState({ selectedMetric: null, metricSeries: [], metricTitle: "" });
        }
    };

    handleReportSelect = async (report) => {
       var start = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : null
       var end = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : null
       var deckIds = ''
       var selectedPlayers = this.state.selectedPlayers
        if (report === "Matched exclusively") 
            var deckIds = this.state.selectedPlayers.map(x => x.selectedDeckId).join(',');
        
        for (var x = 0; x < this.state.selectedPlayers.length; x++) {
            var apiData = await getApiData("GET_DECK_STATS", [selectedPlayers[x].selectedDeckId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), report, deckIds === '' ? "0" : deckIds, start, end]);
            selectedPlayers[x].selectedDeckData = apiData
        }

        this.setState({ selectedPlayers: selectedPlayers, selectedReport: report })
        
          // Refresh the pie chart data
            if (this.state.selectedMetric) {
                this.handleMetricSelect(this.state.selectedMetric, this.state.metricTitle, selectedPlayers);
            }
    }
    

    handleDeckSelection = async (deckId, deckName, accountId) => {
        // Make an API call to get the selected deck data
        var start = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : null
        var end = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : null
        var selectedPlayers = this.state.selectedPlayers
        // Update the state with the selected deck data
        selectedPlayers = selectedPlayers.map((player) => {
            if (player.player.accountId === accountId) {
                player.selectedDeckId = deckId
                player.selectedDeckName = deckName
                return player
            }
            return player; // Return the player as is for others
        })

        const allDeckIdsValid = selectedPlayers.every(player => player.selectedDeckId !== 0);
        if (this.state.selectedReport === "Matched exclusively" && allDeckIdsValid) {
            
            var selectedPlayers = this.state.selectedPlayers
            var deckIds = this.state.selectedPlayers.map(x => x.selectedDeckId).join(',');

            for (var x = 0; x < this.state.selectedPlayers.length; x++) {
                var apiData = await getApiData("GET_DECK_STATS", [selectedPlayers[x].selectedDeckId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, deckIds === '' ? "0" : deckIds, start, end]);
                selectedPlayers[x].selectedDeckData = apiData
            }
        }
        else {
            var selectedDeckData = await getApiData("GET_DECK_STATS", [deckId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, "0", start, end]); // Replace with your actual API call
            var selectedPlayers = this.state.selectedPlayers
            // Update the state with the selected deck data
            selectedPlayers = selectedPlayers.map((player) => {
                if (player.player.accountId === accountId) {
                    player.selectedDeckData = selectedDeckData
                    return player
                }
                return player; // Return the player as is for others
            })
        }
       
        this.setState({ selectedPlayers: selectedPlayers })


        // Refresh the pie chart data
        if (this.state.selectedMetric) {
            this.handleMetricSelect(this.state.selectedMetric, this.state.metricTitle, selectedPlayers);
        }
    }

    render() {
        const { playgroups, selectedPlaygroup, players, selectedPlayer, activeTab, selectedMetric } = this.state;

        return (
            <div>
                <h4>Players in {this.props.selectedPlaygroup.playgroupName}</h4>
                <div className="clearfix">
                    {players.map((player, index) => (
                        <div
                            key={player.accountId}
                            style={{
                                width: '50%',
                                boxSizing: 'border-box',
                                float: 'left',
                                padding: '5px',
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    id={player.accountId}
                                    checked={player.isSelected}
                                    onChange={(e) => this.handleCheckboxChange(player, e)}
                                />{' '}
                                {player.username}
                            </label>
                        </div>
                    ))}
                </div>
                <br />
                <b>Select a report type</b>
                <Dropdown isOpen={this.state.dropdownReportType} toggle={() => this.toggleDropdown('dropdownReportType')}>
                    <DropdownToggle caret>
                        {this.state.selectedReport}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => this.handleReportSelect("Overall performance")}>Overall performance</DropdownItem>
                        <DropdownItem onClick={() => this.handleReportSelect("Overall performance in 1v1")}>Overall performance in 1v1</DropdownItem>
                        <DropdownItem onClick={() => this.handleReportSelect("Overall performance in multiplayer")}>Overall performance in multiplayer</DropdownItem>
                        <DropdownItem onClick={() => this.handleReportSelect("Matched exclusively")}>Matched exclusively</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <br />
                <div><b>Select a date range (No date range = all time)</b></div>
                <DatePicker
                    selected={null}
                    selectsRange
                    startDate={this.state.selectedStartDate}
                    endDate={this.state.selectedEndDate}
                    placeholderText="Select date range"
                    onChange={this.handleDateRangeChange}
                    className="form-control"
                />
                <br />
                <Row>
                    {this.state.players.length > 0 ?
                        this.state.selectedPlayers.map((playerAndDeck) => (
                            <>
                            <div className="col-lg-3 stat-card">
                                <div><h3> {playerAndDeck.player.username}</h3></div>
                                <Dropdown isOpen={this.state[playerAndDeck.player.accountId]} toggle={() => this.toggleDropdown(playerAndDeck.player.accountId)}>
                                    <DropdownToggle caret>
                                        {playerAndDeck.selectedDeckId === 0 ? 'Select Deck' : playerAndDeck.selectedDeckName}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {playerAndDeck.decks.map((deck) => (
                                            <DropdownItem
                                                key={deck.id}
                                                onClick={() => this.handleDeckSelection(deck.id, deck.deckName, playerAndDeck.player.accountId)}
                                            >
                                                {deck.deckName}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                    </Dropdown> <br />
                                    {playerAndDeck.selectedDeckData && (

                                        <div >
                                            <div><b>Total Matches:</b> {playerAndDeck.selectedDeckData.totalMatches}</div>
                                            <div><b>Total Wins:</b> {playerAndDeck.selectedDeckData.totalWins}</div>
                                            <div><b>Total Losses:</b> {playerAndDeck.selectedDeckData.totalLosses}</div>
                                            <div><b>Win Rate:</b> {playerAndDeck.selectedDeckData.winRate}</div>
                                            <div><b>Loss Rate:</b> {playerAndDeck.selectedDeckData.lossRate}</div>
                                        </div>

                                    )}
                            </div>
                            </>
                        ))
                        : ""
                    }
                </Row>
                <br />
                {this.state.selectedPlayers.length > 1 ?
                    <>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown('dropdownOpen')}>
                            <DropdownToggle caret>
                                {selectedMetric ? `Selected Metric: ${this.state.metricTitle}` : 'Select a metric'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.handleMetricSelect("totalMatches", "Total Matches")}>Total Matches</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("totalWins", "Total Wins")}>Total Wins</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("totalLosses", "Total Losses")}>Total Losses</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("winRate", "Win %")}>Win %</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("lossRate", "Loss %")}>Loss %</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <Chart
                            options={this.state.chartOptions}
                            series={this.state.metricSeries}
                            type="pie"
                            width="380"
                        />

                    </>
                    :
                    ""
                }
            </div>
        );
    }
}

export default DeckStats;
