import React, { Component } from 'react';
import { getApiData, postApiData } from '../fetchApi';
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';
import Chart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class PlayerStats extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            players: this.props.playerList,
            generalReports: [],
            selectedMetric: null, // Track the selected metric for the pie chart
            metricSeries: [],
            chartOptions: {},
            dropdownOpen: false,
            dropdownReportType: false,
            metricTitle: "",
            selectedReport: "Overall performance",
            selectedStartDate: null,
            selectedEndDate: null,
            lineChartOptions: {}, // Add options for your line chart
            lineChartSeries: [],
        };
    }

    handleDateRangeChange = async (date) => {
        var start = date[0]
        var end =  date[1] 
        if (start && end) {
            var generalReports = this.state.generalReports;
            var accountIds = '';
            if (this.state.selectedReport === "Matched exclusively")
                accountIds = generalReports.map(report => report.accountId).join(',');

            for (var x = 0; x < this.state.generalReports.length; x++) {
                var apiData = await getApiData("GET_GENERAL_PLAYER_REPORT", [this.state.generalReports[x].accountId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, accountIds === '' ? "0" : accountIds, start.toISOString(), end.toISOString()]);
                generalReports[x] = apiData;
            }

            this.setState({ generalReports: generalReports });

            if (this.state.selectedMetric) {
                this.handleMetricSelect(this.state.selectedMetric, this.state.metricTitle, generalReports);
               // this.handleLineChartUpdate(this.state.selectedMetric, this.state.metricTitle);
            }
            
        }

        this.setState({ selectedStartDate: start, selectedEndDate: end });
    };


    //handleLineChartUpdate = async (selectedMetric, metricTitle) => {
    //    if (this.state.generalReports) {
    //        var accountIds = ''
    //        var start = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : null
    //        var end = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : null
    //        var lineChartSeries = [];
    //        var matchDates = [];
    //        accountIds = this.state.generalReports.map(report => report.accountId).join(',');
    //        var apiData = await getApiData("GET_LINE_SERIES_DATA", [this.state.selectedReport, selectedMetric, accountIds, start, end])
    //        matchDates.push(apiData.matchDates)

    //        const uniqueUsernames = [...new Set(apiData.lineSeriesData.map(obj => obj.username))];

    //        for (var x = 0; x < uniqueUsernames.length; x++) {
    //            const userEntries = apiData.lineSeriesData.filter(entry => entry.username === uniqueUsernames[x]);
    //            const metrics = userEntries.map(entry => entry.metrics);
    //            lineChartSeries.push({ name: uniqueUsernames[x], data: metrics })
    //        }
           
                
            
    //        // Define line chart options
    //        const lineChartOptions = {
    //            chart: {
    //                id: 'line-chart',
    //            },
    //            xaxis: {
    //                categories: matchDates[0], // X-axis data (match dates)
    //            },
    //            yaxis: {
    //                title: {
    //                    text: metricTitle, // Y-axis label
    //                },
    //            },
    //        };

    //        this.setState({ lineChartSeries, lineChartOptions });
    //    }
    //};

    handleCheckboxChange = async (player, e) => {
        var generalReports = this.state.generalReports;
        var start = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : null
        var end = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : null

        if (e.target.checked) {
            if (this.state.selectedReport === "Matched exclusively") {
                // Extract account IDs and join them into a comma-separated string
                var accountIds = generalReports.map(report => report.accountId).join(',');
                accountIds = accountIds === '' ? player.accountId.toString() : accountIds + ',' + player.accountId.toString()
                generalReports.length = 0
                for (var x = 0; x < accountIds.split(',').length; x++) {
                    var apiData = await getApiData("GET_GENERAL_PLAYER_REPORT", [accountIds.split(',')[x], this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, accountIds === '' ? "0" : accountIds, start, end]);
                    generalReports.push(apiData)
                }
            }

            else {
                var apiData = await getApiData("GET_GENERAL_PLAYER_REPORT", [player.accountId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, "0", start, end]);
                generalReports.push(apiData);
            }
           
        } else {
            if (this.state.selectedReport === "Matched exclusively") {
                // Extract account IDs and join them into a comma-separated string
                var accountIds = generalReports.map(report => report.accountId)
                const indexToRemove = accountIds.indexOf(player.accountId);
                if (indexToRemove !== -1) {
                    accountIds.splice(indexToRemove, 1);
                }
                accountIds = accountIds.join(',')
                generalReports.length = 0
                if (accountIds !== '') { 
                for (var x = 0; x < accountIds.split(',').length; x++) {
                    var apiData = await getApiData("GET_GENERAL_PLAYER_REPORT", [accountIds.split(',')[x], this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), this.state.selectedReport, accountIds, start, end]);
                    generalReports.push(apiData)
                    }
                }
            }
            else
                generalReports = generalReports.filter((x) => x.accountId !== player.accountId);
        }
        this.setState({ generalReports: generalReports });

        // Refresh the pie chart data
        if (this.state.selectedMetric) {
            this.handleMetricSelect(this.state.selectedMetric, this.state.metricTitle);
            //this.handleLineChartUpdate(this.state.selectedMetric, this.state.metricTitle)
        }
    };

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    handleMetricSelect = (selectedMetric, metricTitle, generalReports = null) => {
        // Check if selectedMetric is not null and not an empty string
        if (selectedMetric && selectedMetric !== "") {
            // Initialize metricSeries and chartOptions
            let metricSeries = [];
            let chartOptions = {};

            if (generalReports !== null) {
                // Map over the generalReports to extract the metric values
                metricSeries = generalReports.map((report) => {
                    let metricValue = report[selectedMetric];
                    if (selectedMetric === 'Total Matches' || selectedMetric === 'Total Wins' || selectedMetric === 'Total Losses') {
                        // For numeric metrics, parse and aggregate
                        metricValue = parseInt(metricValue, 10); // Convert to integer
                        if (!isNaN(metricValue)) {
                            return metricValue;
                        }
                    } else {
                        // For percentage metrics, use as-is
                        return parseFloat(report[selectedMetric]);
                    }
                    return 0; // Handle cases where the metric is not a valid number
                });

                // Populate chartOptions with labels and colors
                chartOptions = {
                    labels: generalReports.map((report) => report.username), // Labels for each pie slice
                    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FFD700', '#4CAF50', '#FF5733', '#A569BD', '#3498DB'], // Array of colors for each slice
                };
            }
            else {
                // Map over the generalReports to extract the metric values
                metricSeries = this.state.generalReports.map((report) => {
                    let metricValue = report[selectedMetric];
                    if (selectedMetric === 'Total Matches' || selectedMetric === 'Total Wins' || selectedMetric === 'Total Losses') {
                        // For numeric metrics, parse and aggregate
                        metricValue = parseInt(metricValue, 10); // Convert to integer
                        if (!isNaN(metricValue)) {
                            return metricValue;
                        }
                    } else {
                        // For percentage metrics, use as-is
                        return parseFloat(report[selectedMetric]);
                    }
                    return 0; // Handle cases where the metric is not a valid number
                });

                // Populate chartOptions with labels and colors
                chartOptions = {
                    labels: this.state.generalReports.map((report) => report.username), // Labels for each pie slice
                    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FFD700', '#4CAF50', '#FF5733', '#A569BD', '#3498DB'], // Array of colors for each slice
                };
            }

            this.setState({ selectedMetric, metricSeries, chartOptions, metricTitle });
            //this.handleLineChartUpdate(selectedMetric, metricTitle)
        } else {
            // Clear selectedMetric and metricSeries if an invalid metric is selected
            this.setState({ selectedMetric: null, metricSeries: [], metricTitle: "" });
        }
    };

    handleReportSelect = async (report) => {
        var generalReports = []
        var accountIds = '';
        var start = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : null
        var end = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : null
        if (report === "Matched exclusively") {
            // Extract account IDs and join them into a comma-separated string
            accountIds = this.state.generalReports.map(report => report.accountId).join(',');
        }

        for (var x = 0; x < this.state.generalReports.length; x++) {
            var apiData = await getApiData("GET_GENERAL_PLAYER_REPORT", [this.state.generalReports[x].accountId, this.props.selectedPlaygroup.id.toString(), this.props.selectedGameType.id.toString(), report, accountIds ===  '' ? "0" : accountIds, start, end]);
            generalReports.push(apiData)
        }
        
        this.setState({ selectedReport: report, generalReports: generalReports })

        // Refresh the pie chart data
        if (this.state.selectedMetric) {
            this.handleMetricSelect(this.state.selectedMetric, this.state.metricTitle, generalReports);
           // this.handleLineChartUpdate(this.state.selectedMetric, this.state.metricTitle)
        }
    }

    render() {
        const { playgroups, selectedPlaygroup, players, selectedPlayer, activeTab, selectedMetric, selectedDateRange } = this.state;

        return (
            <div>
                <h4>Players in {this.props.selectedPlaygroup.playgroupName}</h4>
                <div className="clearfix">
                    {players.map((player, index) => (
                        <div
                            key={player.accountId}
                            style={{
                                width: '50%',
                                boxSizing: 'border-box',
                                float: 'left',
                                padding: '5px',
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    id={player.accountId}
                                    checked={player.isSelected}
                                    onChange={(e) => this.handleCheckboxChange(player, e)}
                                />{' '}
                                {player.username}
                            </label>
                        </div>
                    ))}
                </div>
                <br />
                <b>Select a report type</b>
                <Dropdown isOpen={this.state.dropdownReportType} toggle={() => this.toggleDropdown('dropdownReportType')}>
                    <DropdownToggle caret>
                        {this.state.selectedReport}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => this.handleReportSelect("Overall performance")}>Overall performance</DropdownItem>
                        <DropdownItem onClick={() => this.handleReportSelect("Overall performance in 1v1")}>Overall performance in 1v1</DropdownItem>
                        <DropdownItem onClick={() => this.handleReportSelect("Overall performance in multiplayer")}>Overall performance in multiplayer</DropdownItem>
                        <DropdownItem onClick={() => this.handleReportSelect("Matched exclusively")}>Matched exclusively</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <br />
                <div><b>Select a date range (No date range = all time)</b></div>
                <DatePicker
                    selected={null}
                    selectsRange
                    startDate={this.state.selectedStartDate}
                    endDate={this.state.selectedEndDate}
                    placeholderText="Select date range"
                    onChange={this.handleDateRangeChange}
                    className="form-control"
                />
                <div><br /></div>
                <Row>
                    {this.state.players.length > 0 ?
                        this.state.generalReports.map((report) => (
                            <div className="col-lg-3 stat-card">
                                <div><h3> {report.username}</h3></div>
                                <div><b>Total Matches:</b> {report.totalMatches}</div>
                                <div><b>Total Wins:</b> {report.totalWins}</div>
                                <div><b>Total Losses:</b> {report.totalLosses}</div>
                                <div><b>Win Rate:</b> {report.winRate}</div>
                                <div><b>Loss Rate:</b> {report.lossRate}</div>
                            </div>
                        ))
                        : ""
                    }
                </Row>
                <br />
                {this.state.generalReports.length > 0 ? 
                    <>
                        <b> Pie Chart Visualizer</b>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown('dropdownOpen')}>
                            <DropdownToggle caret>
                                {selectedMetric ? `Selected Metric: ${this.state.metricTitle}` : 'Select a metric'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.handleMetricSelect("totalMatches", "Total Matches")}>Total Matches</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("totalWins", "Total Wins")}>Total Wins</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("totalLosses", "Total Losses")}>Total Losses</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("winRate", "Win %")}>Win %</DropdownItem>
                                <DropdownItem onClick={() => this.handleMetricSelect("lossRate", "Loss %")}>Loss %</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Row>
                            <div className="col-lg-6">
                           
                                <Chart
                                    options={this.state.chartOptions}
                                    series={this.state.metricSeries}
                                    type="pie"
                                    width="380"
                                />
                            </div>
                            {/*<div className="col-lg-6">*/}
                            {/*    <Chart*/}
                            {/*        options={this.state.lineChartOptions}*/}
                            {/*        series={this.state.lineChartSeries}*/}
                            {/*        type="line" // Use 'line' for a line chart*/}
                            {/*        width="600" // Adjust the width as needed*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </Row>
                   
                      
                    </>
                        :
                        ""
                }
            </div>
        );
    }
}

export default PlayerStats;
