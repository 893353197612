import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { getApiData, postApiData } from './../fetchApi';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            username: ""
        };
    }

    componentDidMount = async () =>
    {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        sessionStorage.setItem('userTimeZone', userTimeZone);

        if (sessionStorage.getItem("x-params")) {
            await postApiData("IS_PREMIUM_CANCELED", [sessionStorage.getItem("x-params")])
            if (!sessionStorage.getItem("x-params-2")) {
                var apiData = await getApiData("GET_USER_INFO", [sessionStorage.getItem("x-params")])
                if (apiData !== "Not Found") {
                    sessionStorage.setItem("x-params-2", apiData.userId)
                    sessionStorage.setItem("x-params-3", apiData.username)
                    this.setState({ userId: apiData.userId, username: apiData.username })
                }
            }

            else {
                this.setState({
                    userId: sessionStorage.getItem("x-params-2"),
                    username: sessionStorage.getItem("x-params-3")
                })
            }
        }
    }

    render() {
        return (
            <div>
                {this.props.showNavMenu && <NavMenu username={this.state.username} userId={this.state.userId} />}
                <Container tag="main">
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
