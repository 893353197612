import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Logo from './../Images/TCG-LADDER-LOGO.png';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }

    logout = () => {
        sessionStorage.clear()
        window.location.href = "/"
    }

  render() {
    return (
      <header>
            <Navbar className="menu-background navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <div className="row">
                    <div className="col-lg-12">
                        <NavbarBrand tag={Link} to="/home"><img style={{ width: "150px" }} src={Logo} alt="Logo" className="navbar-logo" /></NavbarBrand>
                        <NavbarToggler
                            onClick={this.toggleNavbar}
                            style={{ float: 'right', color: '#1E4E59' }}
                            className="mr-2"
                        />
                    </div>
                    <div className="col-lg-12">
                        <b style={{ fontSize: "16px" }}>Display Name:&nbsp; </b><span style={{ fontSize: "16px" }}>{this.props.username}</span>
                    </div>
                    <div className="col-lg-12">
                        <b style={{ fontSize: "16px" }}>User Id: &nbsp;</b><span style={{ fontSize: "16px" }}>{this.props.userId}</span>
                    </div>
                </div>
               
                <div className={`collapse navbar-collapse ${this.state.collapsed ? "" : "show"}`} id="navbarNav">
                    <ul className="navbar-nav flex-grow" style={{ float: "right", position: "relative", bottom:"80px" } }>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/home">Home</NavLink>
              </NavItem>
             
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/deckmenu">Manage Decks</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/history">Match History</NavLink>
            </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/stats">Statistics</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/ladder">Ladder</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/premium">Premium</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/how-to">How to use</NavLink>
            </NavItem>
            <NavItem>
              <button className="btn btn-danger" onClick={this.logout}>Logout</button>
            </NavItem>
            </ul>
            </div>
        </Navbar>
      </header>
    );
  }
}
