import React, { Component } from 'react';
import { getApiData, postApiData } from '../fetchApi';

import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';

import { Layout } from './Layout';
import PlayerStats from './PlayerStats';
import DeckStats from './DeckStats';

class Stats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlaygroup: null,
            selectedPlayer: null,
            playgroups: [],
            playgroupDropdownOpen: false,
            playerDropdownOpen: false,
            activeTab: '1',
            generalReports: [],
            playerList: [],
            key: 0,
            selectedGameType: null,
            gameTypes: []
        };
    }

    componentDidMount = async () => {
        var playgroups = await getApiData("GET_PLAYGROUPS", [sessionStorage.getItem('x-params')]);
        var gameTypes = await getApiData("GET_GAME_TYPES", [sessionStorage.getItem('x-params')])
        this.setState({ playgroups: playgroups, gameTypes: gameTypes });
    }

    handlePlaygroupSelect = async (playgroup) => {
        var playersInGroup = await getApiData("GET_PLAYERS_IN_PLAYGROUP", [playgroup.id])
        this.setState({ playerList: playersInGroup.playerList, selectedPlaygroup: playgroup, key: this.state.key + 1 })
        // Fetch players based on the selected playgroup here (replace with your API call)
    };

    handlePlayerSelect = (player) => {
        this.setState({ selectedPlayer: player });
        // Implement the API request here based on the selected player
    };

    handleGameTypeSelect = (gametype) => {
        this.setState({ selectedGameType: gametype })
    }


    togglePlaygroupDropdown = () => {
        this.setState({ playgroupDropdownOpen: !this.state.playgroupDropdownOpen });
    };

    togglePlayerDropdown = () => {
        this.setState({ playerDropdownOpen: !this.state.playerDropdownOpen });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    };

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    render() {
        const { playgroups, selectedPlaygroup, players, selectedPlayer, activeTab } = this.state;

        return (
            <Layout showNavMenu={true}>
                <div>
                    <h1>Player & Deck Statistics</h1>
                    <br />
                    
                    <Dropdown isOpen={this.state.playgroupDropdownOpen} toggle={this.togglePlaygroupDropdown}>
                        <DropdownToggle caret>
                            {selectedPlaygroup ? selectedPlaygroup.playgroupName : 'Select a playgroup'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.state.playgroups.map((playgroup) => (
                                <DropdownItem key={playgroup.id} onClick={() => this.handlePlaygroupSelect(playgroup)}>
                                    {playgroup.playgroupName}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    <br />
                    <Dropdown isOpen={this.state.dropdownGameTypes} toggle={() => this.toggleDropdown('dropdownGameTypes')}>
                        <DropdownToggle caret>
                            {this.state.selectedGameType ? this.state.selectedGameType.gameType : 'Select a gametype'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.state.gameTypes.map((gametype) => (
                                <DropdownItem
                                    key={gametype.id}
                                    onClick={() => this.handleGameTypeSelect(gametype)}
                                >
                                    {gametype.gameType}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    <br />
                     <Nav tabs>
                        <NavItem>
                            <NavLink style={{cursor:"pointer"}}
                                className={activeTab === '1' ? 'active' : 'inactive'}
                                onClick={() => this.toggleTab('1')}
                            >
                                Player Stats
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                                className={activeTab === '2' ? 'active' : 'inactive'}
                                onClick={() => this.toggleTab('2')}
                            >
                                Deck Stats
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className={activeTab === '1' ? 'tab-pane-active' : ''}>
                            {this.state.selectedPlaygroup && this.state.selectedGameType && (
                                <PlayerStats key={this.state.key} playerList={this.state.playerList} selectedPlaygroup={this.state.selectedPlaygroup} selectedGameType = { this.state.selectedGameType } ></PlayerStats>
                            )}
                            
                        </TabPane>
                        <TabPane tabId="2" className={activeTab === '2' ? 'tab-pane-active' : ''}>
                            {this.state.selectedPlaygroup && (
                            <DeckStats key={this.state.key} playerList={this.state.playerList} selectedPlaygroup={this.state.selectedPlaygroup} selectedGameType = { this.state.selectedGameType } ></DeckStats>
                            )}
                            
                        </TabPane>
                    </TabContent>
                </div>
            </Layout>
        );
    }
}

export default Stats;
