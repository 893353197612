import React, { Component } from 'react';
import {
    Container, Row, Col, Button, Input, Form, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Modal, // Import Modal
    ModalHeader,
    ModalBody,
    ModalFooter, } from 'reactstrap';
import { getApiData, postApiData } from './../fetchApi';
import './../custom.css';
import * as signalR from '@microsoft/signalr';


class Match extends Component {
    constructor(props) {
        super(props);

        //this.connection = new signalR.HubConnectionBuilder()
        //    .withUrl("/Websockets") // Replace with your hub URL
        //    .configureLogging(signalR.LogLevel.Trace)
        //    .build();

        //this.connection.on("MatchStartCheckResponse", (response) => {
        //    // Handle the response from the hub
        //    if (response.isMatchStarted) {
        //        // Match has started, update the UI accordingly
        //        this.setState({ matchStarted: true, matchName: response.data.matchName });
        //    } else {
        //        this.setState({ activePlayersAndDecks: response.data.activePlayersAndDecks });
        //        // Match has not started, update the UI or take action as needed
        //    }
        //});

        var intervalId = null;
        var timeoutId = null;

        this.state = {
            chosenDeck: this.props.selectedDeck,
            winner: null,
            dropdownOpen: false,
            notes: '',
            matchStarted: false,
            activePlayersAndDecks: [],
            dropdownOpen: false,
            dropdownDeck: false,
            selectedWinnerId: 0,
            randomTurnOrder: [],
            matchName: "",
            shuffledArray: [],
            errMsg: "",
            selectedOption: 'sameDecks',
            rematchWithDifferentDecks: false,
            decks: [],
            selectedRematchDeckId: 0,
            selectedDeckName: "",
            errMsgRematch: "",
            timeout: 120, // Initial value, 60 seconds
            showModal: false,
            matchNotesMode: "Save",
            notesId: 0,
            isButtonDisabled: false,
            cannotStartOnYourOwnErr: ""
        };
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    //componentDidMount = async () => {
    //    // Start the SignalR connection when the component mounts
    //    await this.connection.start()
    //        .then(() => {
    //            // Connection to the hub is established
    //            this.connection.invoke("RequestMatchStartCheck", this.props.activeMatchId)
    //                .catch(error => {
    //                    // Handle any errors
    //                });
    //        })
    //        .catch(error => {
    //            console.error('Connection error:', error);
    //        });
    //}

    //componentCleanup = async () => {
    //     //if a host refreshes the app in the lobby, then we cancel the entire match
    //    //if a regular player refreshes the app in the lobby, we remove that player from the match.

    //    //if any player refreshes in the match screen, they should be placed back into the match, unless the match has been concluded.
    //    //if any player freshes the match screen & the match has been conluded, they get redirected to /home
    //    if (!this.state.matchStarted) {
    //        var cleanUpMatchesObj = {
    //            guid: sessionStorage.getItem("x-params"),
    //            matchStarted: this.state.matchStarted.toString(),
    //            activeMatchId: this.props.activeMatchId.toString()
    //        }
    //        await postApiData("CLEAN_UP_MATCHES", [cleanUpMatchesObj])
    //    }
    //}

    componentDidMount = async () => {
        if (this.props.isMatchStarted === 1 && this.state.activePlayersAndDecks.length === 0) {
            var data = await getApiData("CHECK_FOR_MATCH_START", [this.props.activeMatchId]);
            var notes = await getApiData("CHECK_FOR_MATCH_NOTES", [this.props.activeMatchId, sessionStorage.getItem("x-params")])
            var matchNotesMode = "Save";
            var notesId = 0;
            if (data.status === "Canceled" || data.status === "Concluded") {
                if (window.location.href.includes("localhost"))
                    window.location.href = "/home";
                else
                    window.location.href = "https://www.tcgladder.com/home";
            }
            if (notes.matchNotes !== "") {
                matchNotesMode = "Edit"
                notesId = notes.id
            }
            this.setState({ activePlayersAndDecks: data.activePlayersAndDecks, matchName: data.matchName, matchStarted: true, notes: notes.matchNotes, matchNotesMode: matchNotesMode, notesId:notesId });
        }

        if (!this.state.matchStarted) {
            const fetchData = async () => {
                var data = await getApiData("CHECK_FOR_MATCH_START", [this.props.activeMatchId]);
                if (data.status === "Canceled" || data.status === "Concluded")
                {
                    if (window.location.href.includes("localhost"))    
                        window.location.href = "/home";
                    else
                         window.location.href = "https://www.tcgladder.com/home";
                }
                    
                if (!data.isMatchStarted) {
                    this.setState({ activePlayersAndDecks: data.activePlayersAndDecks });
                } else if (data.isMatchStarted) {
                    clearTimeout(this.timeoutId); // Clear the timeout when match starts
                    this.setState({ matchStarted: true, matchName: data.matchName });
                    clearInterval(this.intervalId); // Stop the interval when matchStarted becomes true
                }
            };

            // Immediately fetch data and then set the interval
            fetchData();

            // Set a timeout to clear the interval after 1 minute (60,000 milliseconds)
            this.timeoutId = setTimeout(() => {
                clearInterval(this.intervalId);
                this.cancelMatch(); // Call your timeout function
            }, 120000);

            this.intervalId = setInterval(() => {
                fetchData();
                this.setState((prevState) => ({
                    timeout: prevState.timeout - 1, // Decrease the timeout every second
                }));
            }, 1000); // Fetch data every 3 seconds
        }
    }


    leaveLobby = async () =>
    {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        var leaveMatchObj = { guid: sessionStorage.getItem("x-params"), activeMatchId: this.props.activeMatchId, selectedDeckId: this.props.selectedDeckId.toString() }
        await postApiData("LEAVE_MATCH", [leaveMatchObj])
        if (window.location.href.includes("localhost"))    
            window.location.href = "/home";
        else
            window.location.href = "https://www.tcgladder.com/home";
 
    }

    leaveMatch = async () => {
        var leaveMatchObj = { guid: sessionStorage.getItem("x-params"), activeMatchId: this.props.activeMatchId, selectedDeckId: this.props.selectedDeckId.toString() }
        await postApiData("LEAVE_STARTED_MATCH", [leaveMatchObj])
        if (window.location.href.includes("localhost"))
            window.location.href = "/home";
        else
            window.location.href = "https://www.tcgladder.com/home";
    }



    cancelMatch = async () => {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        await postApiData("CANCEL_MATCH", [this.props.activeMatchId])
        if (window.location.href.includes("localhost"))    
          window.location.href = "/home";
        else
         window.location.href = "https://www.tcgladder.com/home";
    }



    startMatch = async () => {
        if (this.state.activePlayersAndDecks.length < 2) {
            this.setState({ cannotStartOnYourOwnErr: "At least two players are required to start a match" })
        }
        else {
            await postApiData("START_MATCH", [this.props.activeMatchId.toString()])
            this.setState({ matchStarted: true })
        }
    }

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    handleWinnerSelection = (id) => {
        var selectedWinnerId = 0
        if (id !== 0) {
            selectedWinnerId = id
            this.setState({ selectedWinnerId: selectedWinnerId })
        }
        else
            this.setState({ selectedWinnerId: 0 })
    }

    generateRandomTurnOrder = () => {
        const usernames = this.state.activePlayersAndDecks.map(player => player.username);
        const shuffledArray = [...usernames];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        this.setState({shuffledArray: shuffledArray})
    }


    handleRadioChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
    };

    handleRematchDeckSelection = (id) => {
        if (id !== 0) {
            var selectedDeck = this.state.decks.filter(x => x.id === id)
            this.setState({ selectedRematchDeckId: id, selectedDeckName: selectedDeck[0].deckName})
        }
        else
            this.setState({ selectedRematchDeckId: 0, selectedDeckName: ""})

    }

    concludeMatch = async () => {
        if (this.state.selectedWinnerId === 0)
            this.setState({ errMsg: "Please select a winner" })
        else {
            var concludeMatchObj = { winnerid: this.state.selectedWinnerId.toString(), matchid: this.props.activeMatchId.toString() }
            await postApiData("CONCLUDE_MATCH", [concludeMatchObj])

            switch (this.state.selectedOption) {
                case "sameDecks":
                    var accountIdList = this.props.hostAccountId.toString()
                    var deckIdList = this.props.selectedDeckId.toString()
                    var rematchObj = { accountidlist: accountIdList, deckidlist: deckIdList, matchid: this.props.activeMatchId.toString(), differentdecks: "0", rematchcounter: this.props.rematchcounter.toString(), timezone: sessionStorage.getItem("userTimeZone") }
                    var rematchId = await postApiData("CREATE_REMATCH", [rematchObj])
                    this.props.setRematch(rematchId, this.props.selectedDeckId)
                    break;
                case "differentDecks":
                    await postApiData("SET_DIFFERENT_DECKS", [this.props.activeMatchId.toString()])
                    var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), this.props.selectedGameTypeId]);
                    this.setState({ rematchWithDifferentDecks: true, decks: decks })
                    break;
                case "returnHome":
                    if (window.location.href.includes("localhost"))    
                     window.location.href = "/home";
                    else
                     window.location.href = "https://www.tcgladder.com/home";
                    break;
            }
        }
            
    }

    startRematchDifferentDecks = async () => {
        var accountIdList = this.props.hostAccountId.toString() //this.state.activePlayersAndDecks.map(player => player.accountid.toString()).join(',')
        var deckIdList = this.state.selectedRematchDeckId
        var rematchObj = { accountidlist: accountIdList, deckidlist: deckIdList.toString(), matchid: this.props.activeMatchId.toString(), differentdecks: "1", rematchcounter: this.props.rematchcounter.toString(), timezone: sessionStorage.getItem("userTimeZone") }
        var rematchId = await postApiData("CREATE_REMATCH", [rematchObj])
        this.props.setRematch(rematchId, deckIdList)
    }

    checkNextMatch = async () => {
        this.setState({ isButtonDisabled: true });
        var rematchObj = await getApiData("CHECK_REMATCH_STATUS", [this.props.activeMatchId])

        if (rematchObj.status === "DifferentDecks") {
            var decks = await getApiData("GET_DECKS", [sessionStorage.getItem('x-params'), this.props.selectedGameTypeId]);
            this.setState({ rematchStatus: rematchObj.status, decks: decks })
        }

        else if (rematchObj.status === "SameDecks") {
            this.joinRematchSameDecks()
        }

        else if (rematchObj.status === "Waiting") {

            setTimeout(() => {
                this.setState({ isButtonDisabled: false });
            }, 5000);

        }

        else if (rematchObj.status === "Host has not yet concluded the match") {
            this.setState({ errMsgRematch: rematchObj.status })
            setTimeout(() => {
                this.setState({ isButtonDisabled: false });
            }, 5000);
        }
       

        else if (rematchObj.status === "Home") {
            if (window.location.href.includes("localhost"))
                window.location.href = "/home";
            else
                window.location.href = "https://www.tcgladder.com/home";
        }
    }

    joinRematch = async () => {
        var rematchObj = { guid: sessionStorage.getItem("x-params"), deckid: this.state.selectedRematchDeckId.toString(), matchid: this.props.activeMatchId.toString() }
        var rematchId = await postApiData("JOIN_REMATCH", [rematchObj])
        if (rematchId === "Full") {
            this.setState({ errMsgRematch : "The game is full"})
        }
        this.props.setRematch(rematchId, this.state.selectedRematchDeckId)
    }

    joinRematchSameDecks = async () => {
        var rematchObj = { guid: sessionStorage.getItem("x-params"), deckid: this.props.selectedDeckId.toString(), matchid: this.props.activeMatchId.toString() }
        var rematchId = await postApiData("JOIN_REMATCH", [rematchObj])
        this.props.setRematch(rematchId, this.props.selectedDeckId)
    }

    // Function to show the modal
    showCancelMatchModal = () => {
        this.setState({ showModal: true });
    };

    // Function to hide the modal
    hideCancelMatchModal = () => {
        this.setState({ showModal: false });
    };

    handleNotesSave = async () => {
        if (this.state.notes.trim() !== "") {
        var notesObj = { notes: this.state.notes.trim(), matchId: this.props.activeMatchId, guid: sessionStorage.getItem('x-params'), notesId: this.state.notesId }
        var notesId = await postApiData("SAVE_MATCH_NOTES", [notesObj])
            this.setState({ notesId: notesId.id, matchNotesMode: "Edit" })
        }
    }


    render() {
        return (
            <Container className="in-match-container">
                {this.state.matchStarted ? (
                    <>
                        <h3 className="text-center">{this.state.matchName}</h3> <br />
                        <Row>
                            <Col sm="6">
                                
                                <h5>Players and Decks</h5>
                                <ul>
                                    {this.state.activePlayersAndDecks.map((playersAndDecks, index) => (
                                        <li key={index}>
                                            {playersAndDecks.username} - {playersAndDecks.deckname}
                                        </li>
                                    ))}
                                </ul>
                                <br />
                                <div>
                                   
                                </div>
                                {this.props.hostAccountId > 0 ?
                                    <>
                                        <Button className="btn btn-danger" onClick={this.generateRandomTurnOrder}>Generate Random Turn Order</Button>
                                        {this.state.shuffledArray.map((player, index) => (
                                            <div key={index}>{player}</div>
                                        ))}
                                    </>
                                    :  <><b>Note: Leaving the match will still result in a loss for you as long as the host doesn't cancel the match</b>
                                        <div><br /><button onClick={this.leaveMatch} className="btn btn-danger">Leave Match</button></div> <br /></>}
                            </Col>
                            <Col sm="6">
                                
                                    <h5><b>Match Notes :</b></h5><span>The notes will enter edit mode after first save (max 50 characters)</span>
                                    <div>
                                        
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                id="matchNotes"
                                                value={this.state.notes}
                                                maxLength={50}
                                                onChange={(e) => this.setState({ notes: e.target.value })}
                                            />
                                    <br />
                                    <button onClick={this.handleNotesSave} className="btn btn-warning">{this.state.matchNotesMode}</button>
                                </div>
                                    <br />
                                {this.props.hostAccountId > 0 ?
                                    <div className="mb-3">
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown('dropdownOpen')}>
                                            <DropdownToggle caret>
                                                {this.state.selectedWinnerId !== 0 ? this.state.activePlayersAndDecks.filter(x => x.accountid === this.state.selectedWinnerId)[0].username : 'Select Winner'}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem
                                                    key="Select Winner"
                                                    onClick={() => this.handleWinnerSelection(0)}
                                                >
                                                    Select Winner
                                                </DropdownItem>
                                                {this.state.activePlayersAndDecks.map((playersAndDecks) => (
                                                    <DropdownItem
                                                        key={playersAndDecks.accountid}
                                                        onClick={() => this.handleWinnerSelection(playersAndDecks.accountid)}
                                                    >
                                                        {playersAndDecks.username} - {playersAndDecks.deckname}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    :
                                    ""
                                }
                                {this.props.hostAccountId > 0 ?
                                    <>
                                        <FormGroup>
                                            <Label>Rematch Options:</Label>
                                            <div>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="rematchOption"
                                                        value="sameDecks"
                                                        checked={this.state.selectedOption === 'sameDecks'}
                                                        onChange={this.handleRadioChange}
                                                    />{' '}
                                                    Rematch with same decks
                                                </Label>
                                            </div>
                                            <div>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="rematchOption"
                                                        value="differentDecks"
                                                        checked={this.state.selectedOption === 'differentDecks'}
                                                        onChange={this.handleRadioChange}
                                                    />{' '}
                                                    Rematch with different decks
                                                </Label>
                                            </div>
                                            <div>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="rematchOption"
                                                        value="returnHome"
                                                        checked={this.state.selectedOption === 'returnHome'}
                                                        onChange={this.handleRadioChange}
                                                    />{' '}
                                                    Return to playgroup selection
                                                </Label>
                                            </div>
                                        </FormGroup>
                                        <Button onClick={this.concludeMatch} disabled={this.state.rematchWithDifferentDecks} className="btn btn-danger">Conclude Match</Button><div style={{ color: "red" }}>{this.state.errMsg}</div>
                                        <br />
                                        <Button onClick={this.showCancelMatchModal} disabled={this.state.rematchWithDifferentDecks} className="btn btn-danger">Cancel Match</Button>
                                        <br />{this.state.rematchWithDifferentDecks && (
                                            <>
                                            <br />
                                                <Dropdown isOpen={this.state.dropdownDeck} toggle={() => this.toggleDropdown('dropdownDeck')}>
                                                    <DropdownToggle caret>
                                                        {this.state.selectedRematchDeckId !== 0 ? this.state.selectedDeckName : 'Select Deck'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem
                                                            key="Select Deck"
                                                            onClick={() => this.handleRematchDeckSelection(0)}
                                                        >
                                                            Select Deck
                                                        </DropdownItem>
                                                        {this.state.decks.map((deck) => (
                                                            <DropdownItem
                                                                key={deck.id}
                                                                onClick={() => this.handleRematchDeckSelection(deck.id)}
                                                            >
                                                                {deck.deckName}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown><br />
                                                <Button onClick={this.startRematchDifferentDecks} disabled={this.state.selectedRematchDeckId === 0 ? true : false} color="primary">Enter Rematch Lobby</Button>
                                            </>
                                        )}
                                    </>
                                    

                                    :
                                    <>
                                   
                                        <div><Button disabled={this.state.isButtonDisabled} onClick={this.checkNextMatch} className="btn btn-warning">Check Next Match</Button></div><br />
                                        <div style={{color:"red"}}>{this.state.errMsgRematch}</div><br />
                                        {this.state.rematchStatus === "DifferentDecks" && (
                                            <>
                                                <Dropdown isOpen={this.state.dropdownDeck} toggle={() => this.toggleDropdown('dropdownDeck')}>
                                                    <DropdownToggle caret>
                                                        {this.state.selectedRematchDeckId !== 0 ? this.state.selectedDeckName : 'Select Deck'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem
                                                            key="Select Deck"
                                                            onClick={() => this.handleRematchDeckSelection(0)}
                                                        >
                                                            Select Deck
                                                        </DropdownItem>
                                                        {this.state.decks.map((deck) => (
                                                            <DropdownItem
                                                                key={deck.id}
                                                                onClick={() => this.handleRematchDeckSelection(deck.id)}
                                                            >
                                                                {deck.deckName}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown><br />
                                                <Button onClick={this.joinRematch} disabled={this.state.selectedRematchDeckId === 0 ? true : false} color="primary">Rematch!</Button>
                                            </>
                                         )}

                                    </>
                                }
                                    
                            </Col>
                        </Row>
                    </>
                ) : (
                        <>
                            <div className="match-container">
                            {this.props.hostAccountId > 0 ?
                                <>
                                <div>
                                    <h3 className="text-center">WAITING FOR PLAYERS TO JOIN...</h3>
                                    <b>Note: Please make sure you see all players in the lobby before starting!</b>
                                      <div style={{ textAlign: "center" }}><b>Lobby will time out in {this.state.timeout} seconds</b></div>
                                </div>
                                </>
                                    :
                                <>
                                    <h3 className="text-center">WAITING FOR HOST TO START...</h3>
                             
                                    </>   
                            }
                           <br />
                            <div className="row">
                                
                                <div style={{ border: "1px solid black", borderRadius: "4px" }} className="col-sm-12">
                                    <h5>Players in lobby</h5>
                                    {this.state.activePlayersAndDecks.map((playersAndDecks) => (
                                        <div>{playersAndDecks.username} - {playersAndDecks.deckname}</div>
                                    ))}

                                </div>
                            </div>
                            <br /><br />
                                {this.props.hostAccountId > 0 ?
                                    <>
                                        <div style={{color:"red"}}>{this.state.cannotStartOnYourOwnErr}</div>
                                        <Row><div style={{ padding: "15px" }} className="col-lg-6"><Button onClick={this.startMatch} className="btn btn-primary form-control">Start!</Button></div><div style={{ padding: "15px" }} className="col-lg-6"><Button onClick={this.showCancelMatchModal} className="btn btn-danger form-control">Cancel</Button></div></Row>
                                     <Modal className="centered-modal" isOpen={this.state.showModal} toggle={this.hideCancelMatchModal}>
                                      <ModalHeader>Cancel Match Lobby</ModalHeader>
                                      <ModalBody>
                                        Are you sure you want to cancel this match? <br /><b>Note: Canceled matches do not count in statistics.</b>
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button color="secondary" onClick={this.hideCancelMatchModal}>
                                          Close
                                        </Button>
                                        <Button color="danger" onClick={this.cancelMatch}>
                                          Confirm
                                        </Button>
                                      </ModalFooter>
                                        </Modal>
                                    </>
                                    :
                               <Button onClick={this.leaveLobby} className="btn btn-primary">Leave Lobby</Button>
                            }
                            </div>
                           
                        </>
                    
                )}
            </Container>
        );
    }
}

export default Match;
