import React, { Component } from 'react';
import {
    Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItemm, Row, Col, DropdownItem, Nav, Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardBody, CardTitle, } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getApiData, postApiData } from '../fetchApi';


class ViewLadders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlaygroup: null,
            dropdownOpen: false,
            ladderList: [],
            modal: false,
            accountId: this.props.accountId,
            selectedGameType: null,
            errMsg: ""
        };
    };


    // You can fetch playgroups from an API in componentDidMount or using useEffect (for functional components)
    // For simplicity, I'll just assume a static array here.
    handlePlaygroupSelect = async (playgroup) => {
        await this.setState({ selectedPlaygroup: playgroup })
        this.handleGetLadders(playgroup, this.state.selectedGameType);
    };

    handleGetLadders = async (selectedPlaygroup, selectedGameType) => {
        if (selectedGameType && selectedPlaygroup) {
            var laddersInGroup = await getApiData("GET_LADDERS", [selectedPlaygroup.id, selectedGameType.id])
            this.setState({ ladderList: laddersInGroup })
        }
    }

   
    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    handleLadderCalculation = async (metric, ladder) => {
        if (this.state.selectedPlaygroup && this.state.selectedGameType) {
            var ladders = this.state.ladderList
            var index = ladders.findIndex(x => x.id === ladder.id)
            var apiData = await getApiData("GET_LADDER_DATA", [metric, this.state.selectedGameType.id.toString(), this.state.selectedPlaygroup.id.toString(), ladder.accountIdList, ladder.ladderDateStart ? new Date(ladder.ladderDateStart).toISOString() : null, ladder.ladderDateEnd ? new Date(ladder.ladderDateEnd).toISOString() : null])
            ladders[index].data = apiData
            ladders[index].selectedMetric = metric

            this.setState({ ladderList: ladders, errMsg:"" })
        }
        else {
            this.setState({errMsg:"Playgroup and game type are required"})
        }
 

    }



    handleGameTypeChange = async (option) => {
        await this.setState({ selectedGameType: option })
        this.handleGetLadders(this.state.selectedPlaygroup, option);
    }
    

    // Function to toggle the modal and set ladderIdToDelete
    toggleModal = (ladderId) => {
        this.setState({
            modal: !this.state.modal,
            ladderIdToDelete: ladderId,
        });
    };

    // Function to handle ladder deletion (replace with your actual logic)
    handleLadderDeletion = async () => {
        const { ladderIdToDelete } = this.state;
        // Add your ladder deletion logic here
        await postApiData("DELETE_LADDER", [ladderIdToDelete])
        var ladderList = this.state.ladderList;
        ladderList = ladderList.filter(x => x.id !== ladderIdToDelete)
        this.toggleModal(null); // Close the modal
        this.setState({ladderList:ladderList})
    };

    

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    };

    render() {
        const { playgroups, selectedPlaygroup, ladderName, startDate, endDate, metric, dropdownOpen, playerList, selectedPlayers } = this.state;
        return (
            <>
        <Row>
            <Col style={{ padding: "15px" }} xs="12" md="6">
                <b>Playgroup</b>
                <Dropdown isOpen={this.state.dropdownPlayers} toggle={() => this.toggleDropdown('dropdownPlayers')}>
                    <DropdownToggle caret>
                        {selectedPlaygroup ? selectedPlaygroup.playgroupName : 'Select a playgroup'}
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.props.playgroups.map((playgroup) => (
                            <DropdownItem
                                key={playgroup.id}
                                onClick={() => this.handlePlaygroupSelect(playgroup)}
                            >
                                {playgroup.playgroupName}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </Col>
                </Row>
                <Row>
                     <Col style={{ padding: "15px" }} xs="12" md="6">
                        <b>Game type</b>
                        <Dropdown isOpen={this.state.dropdownGameType} toggle={() => this.toggleDropdown('dropdownGameType')}>
                            <DropdownToggle caret>
                                {this.state.selectedGameType ? this.state.selectedGameType.gameType : 'Select a game type'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {this.props.gameTypes.map((option) => (
                                    <DropdownItem key={option.id} onClick={() => this.handleGameTypeChange(option)}>
                                        {option.gameType}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
            </Col>
                </Row>
                <div style={{color:"red"}}>{this.state.errMsg}</div>
           
               

            <Row>
                    {this.state.ladderList.map((ladder, parentIndex) =>
                    (
                        <Col style={{ padding: "15px" }} className="col-lg-4 mobile-custom">

                            <Card className="ladder-data-container">
                                {this.props.accountId === ladder.accountId ? <div style={{ height: "10px", float: "right", textAlign: "right", color: "red", fontSize: "18px", fontWeight: "bold", padding: "15px" }}><span style={{ cursor: "pointer" }} onClick={() => this.toggleModal(ladder.id)} id={ladder.id}>X</span></div> : ""}
                             <CardTitle style={{ padding: "15px" }}><b style={{ fontSize: "18px" }}>{ladder.ladderName}</b>  {ladder.ladderDateStart ? (
                                 <>
                                 <p>
                                     <b>Start Date:</b> {ladder.ladderDateStart.split("T")[0]} <b>End Date:</b>{" "}
                                     {ladder.ladderDateEnd.split("T")[0]}
                                 </p>
                                     
                                 </>
                             ) :<><br /><b>All Time</b></>}
                                   
                                        <div >
                                            <br />
                                            <Dropdown isOpen={this.state['dropdownMetrics' + parentIndex]} toggle={() => this.toggleDropdown('dropdownMetrics' + parentIndex)}>
                                                <DropdownToggle caret>
                                                    {ladder.selectedMetric ? ladder.selectedMetric : 'Select a metric'}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {['Total Matches', 'Total Wins', 'Total Losses', 'Win Rate', 'Loss Rate'].map((option) => (
                                                        <DropdownItem key={option} onClick={() => this.handleLadderCalculation(option, ladder)}>
                                                            {option}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                       
                                    
                                

                             </CardTitle>
                             <CardBody>
                                 {ladder.data ? ladder.data.map((ladderDataObj, innerIndex) => (
                                     <div className="row mobile-custom-row">

                                         {innerIndex === 0 ? (
                                             <>
                                                 <div className="mobile-custom-col" style={{
                                                     width: '33%',
                                                     boxSizing: 'border-box',
                                                     float: 'left',
                                                     padding: '5px', display: 'block'
                                                 }}><b>Rank</b></div>
                                                 <div className="mobile-custom-col" style={{
                                                     width: '33%',
                                                     boxSizing: 'border-box',
                                                     float: 'left',
                                                     padding: '5px', display: 'block'
                                                 }}><b>Username</b></div>
                                                 <div className="mobile-custom-col"  style={{
                                                     width: '33%',
                                                     boxSizing: 'border-box',
                                                     float: 'left',
                                                     padding: '5px', display: 'block'
                                                 }}><b>{ladderDataObj.metric}</b></div>
                                             </>
                                         ) : null}

                                         <div className="mobile-custom-col" style={{
                                             width: '33%',
                                             boxSizing: 'border-box',
                                             float: 'left',
                                             padding: '5px', display: 'block'
                                         }}><span><b>{innerIndex + 1}</b></span></div>
                                         <div className="mobile-custom-col" style={{
                                             width: '33%',
                                             boxSizing: 'border-box',
                                             float: 'left',
                                             padding: '5px', display: 'block'
                                         }}><span>{ladderDataObj.username}</span></div>
                                         <div className="mobile-custom-col" style={{
                                             width: '33%',
                                             boxSizing: 'border-box',
                                             float: 'left',
                                             padding: '5px', display: 'block'
                                         }}><span>{ladderDataObj.value}</span></div>

                                     </div>
                                     ))
                                     :
                                     ""
                                 }
                                </CardBody>
                            </Card>
                      
                     </Col>
                 ))}
                </Row>
                {/* Modal for ladder deletion confirmation */}
                <Modal className="centered-modal" isOpen={this.state.modal} toggle={() => this.toggleModal(null)}>
                    <ModalHeader toggle={() => this.toggleModal(null)}>
                        Confirm Deletion
                    </ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this ladder?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.toggleModal(null)}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={this.handleLadderDeletion}>
                            Confirm
                        </Button>
                    </ModalFooter>
                </Modal>
          </>
     
        );
    }
}
    


export default ViewLadders;
