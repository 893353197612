import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getApiData, postApiData } from './../fetchApi';
import Playgroup from './PlayGroup';
import Match from './Match';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            decks: [],
            matches: [],
            dropdownOpen: false,
            accountId: 0,
            matchName: "",
            userId: "",
            refreshEnd: false,
            selectedDeckId: 0,
            selectedDeckName: "",
            activeTab: 'matches',
            playerList: [],
            canCreate: false,
            gameTypes: [],
            selectedGameTypeId: 0,
            selectedGameTypeName: "",
            matchErr: "",
            addPlayerErr: "",
            showModal: false,
            modalType: 'leave',
            selectedNewHostId: 0,
            hostRequiredErr: "",
            isEditing: false,
            editedPlaygroupName: ''

        };
    }

    componentDidMount = async () => {
        var matches = await getApiData("GET_MATCHES", [this.props.playgroupObj.id]);
        var playersInGroup = await getApiData("GET_PLAYERS_IN_PLAYGROUP", [this.props.playgroupObj.id])
        var accountId = await getApiData("GET_ACCOUNT_ID", [sessionStorage.getItem('x-params')])
        var gameTypes = await getApiData("GET_GAME_TYPES", [sessionStorage.getItem('x-params')])
       
        
        this.setState({ matches: matches, accountId: accountId.id, playerList: playersInGroup.playerList, gameTypes: gameTypes});
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    };
     
    handleGameTypeSelection = (id, gameTypeName) => {

        this.setState({ selectedGameTypeId: id, selectedGameTypeName: gameTypeName, selectedDeckId: 0, selectedDeckName: "Select Deck" })
        this.props.handleGameTypeSelection(id)
    }

    handleDeckSelection = (id, deckName) => {
        var canCreate = false
        if (id > 0 && this.checkPermissions())
            canCreate = true
        this.setState({ selectedDeckId: id, selectedDeckName: deckName, canCreate: canCreate })
        this.props.handleDeckSelection(id, deckName)
    }

    handleMatchNameChange = (e) => {
        this.setState({matchName: e.target.value})
    }

    handleUserIdChange = (e) => {
        this.setState({ userId: e.target.value })
    }

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    hostNewMatch = () => {
        if (this.state.matchName == "") {
            this.setState({ matchErr: "Match Name Required" })
        }
        else {
            this.props.hostNewMatch(this.state.accountId, this.state.matchName, this.props.playgroupObj.id)
        }
        
    }

    addPlayerToPlaygroup = async () => {
        var addPlayerObj = { playgroupid: this.props.playgroupObj.id.toString(), userid: this.state.userId }
        var response = await postApiData("ADD_PLAYER_TO_PLAYGROUP", [addPlayerObj])
        if (response === 'Duplicate')
            this.setState({ addPlayerErr: "Player is already in this playgroup" })
        else if (response === "Required")
            this.setState({ addPlayerErr: "User id is required" })
        else if (response === "Not Found")
            this.setState({ addPlayerErr: "Player not found" })
        else if (response === "Premium Max" || response === "Freemium Max")
            this.setState({ addPlayerErr: "This player cannot join anymore playgroups" })
        else {
            this.setState({ userId: "", addPlayerErr: "" })
            this.props.handlePlaygroupRefresh()
        }
           

    }

    joinMatch = async (e) => {
        this.props.joinMatch(e.target.name, this.state.accountId)
    }

    checkPermissions = () => {
        //add Permissions
        var splitString = this.props.playgroupObj.modIdList.split(',')
        if (!splitString.includes(this.state.accountId.toString()))
            return false;
        else
            return true;
    }

    changePermissions = async (e) => {
        var setPlaygroupModObj = { playgroupid: this.props.playgroupObj.id.toString(), accountid: e.target.id.toString(), ismod: e.target.checked.toString() }
        await postApiData("SET_PLAYGROUP_MOD", [setPlaygroupModObj])
        var Modified = this.state.playerList
        Modified = Modified.map(player => {
            // Check if the player's accountId matches e.target.id
            if (player.accountId.toString() === e.target.id.toString()) {
                // Update the isMod property of the matched player
                player.isMod = !e.target.checked
            }
            return player; // Return the player object as is if it doesn't match
        });
        this.setState({ playerList: Modified })
    }

    checkModList = () => {
        var splitModList = this.props.playgroupObj.modIdList.split(',')
        if (splitModList.includes(this.state.accountId.toString()))
           return true;
        return false;
       
    }

    openModal = (event) => {
        // Determine the modal type based on the event
        if (event === 'delete') {
            this.setState({ showModal: true, modalType: 'delete' });
        } else {
            this.setState({ showModal: true, modalType: 'leave' });
        }
    }

    closeModal = () => {
        this.setState({ showModal: false, hostRequiredErr: "" });
    }

    handlePlaygroupDelete = async () =>
    {
        this.closeModal()
        this.props.handlePlaygroupDelete(this.props.playgroupObj.id)
    }

    handleLeaveClick = async () => {
        if (this.state.selectedNewHostId === 0 || this.state.selectedNewHostId === '')
            this.setState({ hostRequiredErr: "Please select a new host" })
        else {
            this.closeModal()
            this.props.handleLeaveClick(this.props.playgroupObj.id, this.state.selectedNewHostId)
        }
       
    }

    handleLeaveUserClick = async () => {
        this.closeModal()
        this.props.handleLeaveUserClick(this.props.playgroupObj.id)
    }

    handleNewHostSelection = (e) => {
        this.setState({selectedNewHostId: e.target.value})
    }

    handleEditClick = () => {
        this.setState({ isEditing: true, editedPlaygroupName: this.props.playgroupObj.playgroupName });
    }

    handleSaveClick = async () => {
        var playgroupObj = { playgroupname: this.state.editedPlaygroupName, id: this.props.playgroupObj.id }
        await postApiData("EDIT_PLAYGROUP_NAME", [playgroupObj])
        this.setState({isEditing: false})
    }

    handleInputChange = (event) => {
        this.setState({ editedPlaygroupName: event.target.value });
    }

    render() {
        const isHost = this.state.accountId === this.props.playgroupObj.accountId;
        const modalType = this.state.modalType; 
        return (
            <>
            <Card style={{ textAlign: 'center' }} className="mb-3">
                <CardBody>
                    {this.state.accountId !== this.props.playgroupObj.accountId ?
                            <i style={{ fontSize: "25px", padding: "10px", color: "#3FA693", cursor: "pointer"}} onClick={() => this.openModal('leave')} className="fa fa-sign-out"></i>
                           
                        
                        :
                        <>
                                <i style={{ fontSize: "25px", padding: "10px", color: "#3FA693", cursor:"pointer"}} onClick={() => this.openModal('leave')} className="fa fa-sign-out"></i>
                                <i style={{ fontSize: "25px", padding: "10px", color: "red", cursor:"pointer" }} onClick={() => this.openModal('delete')} className="fa fa-trash"></i>
                        </>
                        
                    }
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 'matches' ? 'active' : 'inactive'}
                                onClick={() => this.toggleTab('matches')}
                            >
                                Matches
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 'playerList' ? 'active' : 'inactive'}
                                onClick={() => {
                                    // Fire your API request for player list here
                                    // Implement the API request logic
                                    this.toggleTab('playerList');
                                }}
                            >
                                Player List
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <br />
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane className={this.state.activeTab === 'matches' ? 'tab-pane-active' : ''} tabId="matches">
                                {/* Your "Matches" content */}
                                {this.state.isEditing ? (
                                    <div style={{ display: "inline-block" }}>
                                        <input style={{ marginBottom:"10px" }}
                                            type="text"
                                            value={this.state.editedPlaygroupName}
                                            maxLength={30}
                                            onChange={this.handleInputChange}
                                            className="form-control"
                                        /> 
                                        <button className="btn btn-primary" style={{ display: "inline-block" }} onClick={this.handleSaveClick}>Save</button>
                                    </div>
                                ) : (
                                    <div style={{ display: "inline-block" }}>
                                       <h5 style={{ display: "inline-block" }}>{this.state.editedPlaygroupName !== '' ? this.state.editedPlaygroupName : this.props.playgroupObj.playgroupName}</h5> &nbsp;
                                            {this.state.accountId !== this.props.playgroupObj.accountId ? "" : <i style={{ display: "inline-block" }} className="fa fa-edit" onClick={this.handleEditClick} />}
                                    </div>
                                )}
                            
                            {this.props.playgroupObj.disabled && (<><br/><b>Reactivate premium to access playgroup</b></>)}
                            {this.state.accountId !== this.props.playgroupObj.accountId ? "" : (
                                !this.props.playgroupObj.disabled && (
                                <>
                                    <br />
                                    <b>Enter player user Id</b> <br />
                                    <Input
                                        type="text"
                                        onChange={this.handleUserIdChange}
                                        value={this.state.userId}
                                        placeholder="User Id"
                                    />
                                    <br />
                                    <span style={{color:"red"}}>{this.state.addPlayerErr}</span>
                                    <div>
                                        <button
                                            onClick={this.addPlayerToPlaygroup}
                                            disabled={this.state.accountId !== this.props.playgroupObj.accountId ? true : false}
                                            className="btn btn-custom"
                                        >
                                            Add player to playgroup
                                        </button>
                                    </div>
                                    <br />
                                    </>
                                )
                                
                            )}
                            {!this.props.playgroupObj.disabled && (
                                <Dropdown isOpen={this.state.dropdownOpenGameType} toggle={() => this.toggleDropdown('dropdownOpenGameType')}>
                                    <DropdownToggle style={{ width: '100%' }} caret>
                                        {this.state.selectedGameTypeId !== 0 ? this.state.selectedGameTypeName : 'Select game type'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            key="Select Deck"
                                            onClick={() => this.handleGameTypeSelection(0, "Select game type")}
                                        >
                                            Select game type
                                        </DropdownItem>
                                        {this.state.gameTypes.map((gameType) => (
                                            <DropdownItem key={gameType.id} onClick={() => this.handleGameTypeSelection(gameType.id, gameType.gameType)}>
                                                {gameType.gameType}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>)}
                            {this.state.selectedGameTypeId > 0 && (
                                <>
                                    <br />
                                    <Dropdown isOpen={this.state.dropdownOpenEdit} toggle={() => this.toggleDropdown('dropdownOpenEdit')}>
                                        <DropdownToggle style={{ width: '100%' }} caret>
                                            {this.state.selectedDeckId !== 0 ? this.state.selectedDeckName : 'Select Deck'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                key="Select Deck"
                                                onClick={() => this.handleDeckSelection(0, "Select Deck")}
                                            >
                                                Select Deck
                                            </DropdownItem>
                                            {this.props.decks.filter(x => x.gameTypeId === this.state.selectedGameTypeId).map((deck) => (
                                                <DropdownItem key={deck.id} onClick={() => this.handleDeckSelection(deck.id, deck.deckName)}>
                                                    {deck.deckName}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </>
                            )}<br />
                            { !this.checkModList() ? "" :
                                <>
                            <Input type="text" onChange={this.handleMatchNameChange} placeholder="Match Name"></Input>
                            <br />
                                    <div>
                                        <span style={{color:"red"}}>{this.state.matchErr}</span>
                            <Button
                                onClick={this.hostNewMatch}
                                disabled={!this.state.canCreate}
                                className="btn btn-primary"
                            >
                                Create Match
                                </Button>
                            </div>
                                </>}
                            <br />
                            {!this.props.playgroupObj.disabled && (
                                <>
                                <CardTitle>Pending & In Progress Matches:</CardTitle>
                     
                                <span>{this.state.errMsg}</span>
                            
                                <div>
                              
                                    <div>{this.props.createMatchErr}</div>
                                    {this.state.matches.length === 0 ? <b>No ongoing matches</b> : ""}
                                    {this.state.matches.map((match) => (
                                        <>
                                            <div style={{ border: "1px solid black", padding: "5px" }}>
                                                <div name={match.id}>
                                                    <span>
                                                        <b>Match Name:</b> {match.matchName} <br />
                                                        <b>Status:</b> {match.status} <br />
                                                        <b>Game type:</b> {match.gameTypeName}
                                                    </span>
                                                </div>
                                                <div>
                                                    {this.state.selectedGameTypeId === match.gameTypeId ?
                                                        <Button
                                                            name={match.id}
                                                            disabled={match.status === "In Progress" || this.state.selectedDeckId === 0}
                                                            onClick={this.joinMatch}
                                                            className="btn btn-primary"
                                                        >
                                                            Join Match
                                                        </Button>

                                                        :

                                                        <b>Cannot join: Check game type</b>
                                                    }

                                                </div>
                                                <div style={{ color: "red" }}>{this.props.errMsgJoinGame}</div>
                                            </div>
                                            <br />
                                        </>

                                    ))}
                                </div>
                                </>)}
                        </TabPane>
                        <TabPane className={this.state.activeTab === 'playerList' ? 'tab-pane-active' : ''} tabId="playerList">
                            <div className="container">
                                {this.state.playerList.map((player, index) => (
                                    <div className="row" key={player.accountId}>
                                        <div className="col-lg-6 col-12">
                                            {index === 0 && <div><b>Username</b></div>}
                                            {player.username}
                                        </div>

                                        <div className="col-lg-6 col-12">
                                            {index === 0 && <div><b>Is Mod?</b></div>}
                                            {this.state.accountId !== this.props.playgroupObj.accountId ?
                                                player.isMod === true ? (player.accountId !== this.props.playgroupObj.accountId ? <span>Yes</span> : <span>Host</span>) : <span>No</span>
                                                : (player.accountId !== this.props.playgroupObj.accountId ?
                                                    <input
                                                        onChange={this.changePermissions}
                                                        type="checkbox"
                                                        id={player.accountId}
                                                        checked={player.isMod === true}
                                                        disabled={this.state.accountId !== this.props.playgroupObj.accountId || index === 0}
                                                    />
                                                    : <span>Host</span>)
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
                <div
                    className={`modal fade ${this.state.showModal ? 'show' : ''}`}
                    style={this.state.showModal ? { display: 'block' } : {}}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                    onClick={this.closeModal}
                >
                    <div className={`modal-dialog modal-center`} role="document">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalLabel">
                                    {modalType === 'delete'
                                        ? 'Are you sure you want to delete this playgroup?'
                                        : isHost
                                            ? 'You are the host of this playgroup.'
                                            : 'Are you sure you want to leave this playgroup?'}
                                </h5>
                            </div>
                            <div className="modal-body">
                                {modalType === 'delete' ? (
                                    <p>
                                        The match history of this playgroup and all of its ladders will be removed as well.
                                        This is irreversible.
                                    </p>
                                ) : isHost ? (
                                    <div>
                                        <p>If you wish to leave, please assign a new Host.</p>
                                            <div>
                                                <select className="form-control" onChange={this.handleNewHostSelection}>
                                                    <option key={0} value={0}>Select new host</option>
                                                    {this.state.playerList.filter(x => x.accountId !== this.state.accountId).map((player, index) => (
                                                        <option key={player.accountId} value={player.accountId}>{player.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : (
                                    <p>Are you sure you want to leave this playgroup?</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div style={{ color: "red" }}>{this.state.hostRequiredErr}</div>
                                {modalType === 'delete' ? 
                                    <button type="button" className="btn btn-danger" onClick={this.handlePlaygroupDelete}>Delete</button>
                                    :
                                    isHost ?
                                        <button type="button" className="btn btn-primary" onClick={this.handleLeaveClick}>Leave</button>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={this.handleLeaveUserClick}>Leave</button>
                                
                                }
                               
                                <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
