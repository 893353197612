import React, { Component } from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink, Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getApiData, postApiData } from '../fetchApi';
import { Layout } from './Layout';

class MatchHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchHistory: [], // To store match history data
            currentPage: 1, // Current page
            itemsPerPage: 15, // Matches per page
            startDate: null, // Start date for filtering
            endDate: null, // End date for filtering
            matchName: '', // Ladder name filter
            totalMatchCount: 0,
            selectedPlaygroup: null,
            playgroups: [],
            gametypes: [],
            selectedGameType: null
        };
    }

    componentDidMount = async () => {
        var playgroups = await getApiData("GET_PLAYGROUPS", [sessionStorage.getItem('x-params')]);
        var gameTypes = await getApiData("GET_GAME_TYPES", [sessionStorage.getItem('x-params')])
        this.setState({playgroups: playgroups, gametypes: gameTypes})
        //this.fetchData();
    }

    fetchData = async ()  => {
        const { startDate, endDate, matchName, currentPage, itemsPerPage } = this.state;
        var skip = (currentPage - 1) * itemsPerPage
        var take = itemsPerPage
        
        var apiData = await getApiData("GET_MATCH_HISTORY", [sessionStorage.getItem('x-params'), this.state.selectedPlaygroup.id.toString(), this.state.selectedGameType.id.toString(), skip.toString(), take.toString(), startDate ? startDate.toISOString() : null, endDate ? endDate.toISOString() : null, matchName])
        this.setState({ matchHistory: apiData.paginatedMatches, totalMatchCount: apiData.totalMatchCount })
       
    }

    handlePlaygroupSelect = (playgroup) =>
    {
        this.setState({selectedPlaygroup:playgroup})
        if (this.state.selectedPlaygroup !== null && this.state.selectedGameType !== null)
            this.fetchData();
    }

    handleGameTypeSelect = (gametype) =>
    {
        this.setState({selectedGameType:gametype})
        if (this.state.selectedPlaygroup !== null && this.state.selectedGameType !== null)
            this.fetchData();
    }

    handlePaginationClick = (event) => {
        const selectedPage = parseInt(event.target.value, 10);
        this.setState({ currentPage: selectedPage }, () => {
            this.fetchData();
        });
    };

    filterMatches = () => {
        if (this.state.selectedPlaygroup !== null && this.state.selectedGameType !== null)
            this.fetchData();
    }

    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    render() {
        const { matchHistory, currentPage, itemsPerPage, startDate, endDate, matchName } = this.state;

        return (
            <Layout showNavMenu={true}>
            <div>
                <div>
                <Label for="playgroupSelect"><b>Select playgroup (Required)</b></Label>
                                    <Dropdown isOpen={this.state.dropdownPlayers} toggle={() => this.toggleDropdown('dropdownPlayers')}>
                                        <DropdownToggle caret>
                                            {this.state.selectedPlaygroup ? this.state.selectedPlaygroup.playgroupName : 'Select a playgroup'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.playgroups.map((playgroup) => (
                                                <DropdownItem
                                                    key={playgroup.id}
                                                    onClick={() => this.handlePlaygroupSelect(playgroup)}
                                                >
                                                    {playgroup.playgroupName}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                </div>
                <div>
                <Label for="gametypeSelect"><b>Select game type (Required)</b></Label>
                                    <Dropdown isOpen={this.state.dropdownGameTypes} toggle={() => this.toggleDropdown('dropdownGameTypes')}>
                                        <DropdownToggle caret>
                                            {this.state.selectedGameType ? this.state.selectedGameType.gameType : 'Select a gametype'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.gametypes.map((gametype) => (
                                                <DropdownItem
                                                    key={gametype.id}
                                                    onClick={() => this.handleGameTypeSelect(gametype)}
                                                >
                                                    {gametype.gameType}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                </div>
                    <div>
                    <b>Enter date range (optional)</b><br />
                    <DatePicker
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(dates) => {
                            const [start, end] = dates;
                            this.setState({ startDate: start, endDate: end });
                        }}
                        selectsRange
                        className="form-control"
                        /> 
                    <div><br /></div>
                    <Input
                        type="text"
                        placeholder="Filter by Match Name (optional)"
                        value={matchName}
                        onChange={(e) => this.setState({ matchName: e.target.value })}
                    />
                    </div>
                    <br />
                    <Button className="btn btn-primary" onClick={this.filterMatches}>Get Matches</Button>
                    <div><br /></div>
                    {matchHistory.length > 0 && (matchHistory.map((match, index) => (
                    <Row key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                        <Col>
                                <p>Match Name: {match.matchName} ({match.matchDate.split('T')[0]})</p>
                                <p>Winner: {match.winnerUserName}</p>
                            </Col>
                            <Col>
                                <p>Players in match: {match.username}</p>
                                <p>Decks in match: {match.deckName}</p>
                            </Col>
                            <Col>
                                <p>{match.matchNotes}</p>
                               
                            </Col>
                           
                    </Row>
                )))} 
                    {/* Render the page number dropdown */}
                    <div style={{padding:"15px", marginBottom:"10px"}}>
                    <b>Select page:</b>
                    
                        <select className="form-control" id="pageSelect" style={{ marginBottom: "10px" }} value={this.state.currentPage} onChange={this.handlePaginationClick}>
                        {Array.from({ length: Math.ceil(this.state.totalMatchCount / this.state.itemsPerPage) }).map((_, index) => (
                            <option key={index} value={index + 1}>
                                Page {index + 1}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default MatchHistory;
