import React, { Component } from 'react';
import {
    Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, Row, Col, DropdownItem, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getApiData, postApiData } from '../fetchApi';
import { Layout } from './Layout';
import ViewLadder from './ViewLadders'

class Ladders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playgroups: [], // Populated from an API
            selectedPlaygroup: null,
            ladderName: '',
            startDate: null,
            endDate: null,
            dropdownOpen: false,
            playerList: [],
            selectedPlayers: [],
            activeTab: '1',
            errMsg: "",
            accountId: 0,
            filteredPlaygroups: [],
            gameTypes: [],
            selectedGameType: null
        };
    };


    // You can fetch playgroups from an API in componentDidMount or using useEffect (for functional components)
    // For simplicity, I'll just assume a static array here.
    componentDidMount = async () => {
        var accountId = await getApiData("GET_ACCOUNT_ID", [sessionStorage.getItem("x-params")])
        var playgroups = await getApiData("GET_PLAYGROUPS", [sessionStorage.getItem('x-params')]);
        var gameTypes = await getApiData("GET_GAME_TYPES", [sessionStorage.getItem('x-params')])
        var filteredplaygroups = playgroups.filter(x => x.accountId === accountId.id)
        this.setState({ playgroups: playgroups, accountId: accountId.id, filteredPlaygroups: filteredplaygroups, gameTypes: gameTypes });
    }

    handlePlaygroupSelect = async (playgroup) => {
        var playersInGroup = await getApiData("GET_PLAYERS_IN_PLAYGROUP", [playgroup.id])
        this.setState({ playerList: playersInGroup.playerList, selectedPlaygroup: playgroup })
        // Fetch players based on the selected playgroup here (replace with your API call)
    };

    handleGameTypeSelect = async (gametype) => {
        this.setState({ selectedGameType : gametype })
    }

    handlePlayerClick = (playerId) => {
        const { playerList, selectAll } = this.state;

        if (playerId === 'selectAll') {
            const updatedPlayerList = playerList.map((player) => ({
                ...player,
                selected: !selectAll,
            }));

            this.setState({
                playerList: updatedPlayerList,
                selectAll: !selectAll,
            });
        }
        else {
            const updatedPlayerList = playerList.map((player) => {
                if (player.accountId === playerId) {
                    player.selected = !player.selected;
                }
                return player;
            });

            const selectAll = updatedPlayerList.every((player) => player.selected);

            this.setState({
                playerList: updatedPlayerList,
                selectAll,
            });
        }
    }



    toggleDropdown = (dropdownName) => {
        this.setState((prevState) => ({
            [dropdownName]: !prevState[dropdownName]
        }));
    };

    handleCreateLadder = async () => {
        const selectedPlayers = this.state.playerList.filter(player => player.selected);
        var apiData = []
        if (this.state.selectedPlaygroup) {
            var createLadderObj = {
                laddername: this.state.ladderName, ladderdatestart: this.state.startDate === null ? null : this.state.startDate.toISOString(),
                ladderdateend: this.state.endDate === null ? null : this.state.endDate.toISOString(), playgroupid: this.state.selectedPlaygroup.id, gameTypeId: this.state.selectedGameType.id
                , accountidlist: selectedPlayers.map(player => player.accountId).join(','), guid: sessionStorage.getItem("x-params")
            }
            apiData = await postApiData("CREATE_LADDER", [createLadderObj])
        }
        else {
            apiData= "PlaygroupMissing";
        }
       

        if (apiData) {
            switch (apiData) {
                case "Max":
                    this.setState({ errMsg: "Cannot create anymore ladders for this playgroup. (4 max, 12 with premium)" })
                    break;
                case "Limit":
                    this.setState({ errMsg: "You have reached the limit on ladder creation" })
                    break;
                case "NameMissing":
                    this.setState({ errMsg: "Ladder name required" })
                    break;
                case "PlayersMissing":
                    this.setState({ errMsg: "Please select at least one player" })
                    break;
                case "PlaygroupMissing":
                    this.setState({ errMsg: "Please select a playgroup" })
                    break;
            }
        }

        else {
            this.setState({
                selectedPlaygroup: null, ladderName: "", startDate: null,
                endDate: null, playerList: [], errMsg: "Ladder created successfully!", selectAll: false})
        }
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    };

    render() {
        const { playgroups, selectedPlaygroup, ladderName, startDate, endDate, dropdownOpen, playerList, selectedPlayers } = this.state;

        return (
            <Layout showNavMenu={true}>
                <Nav tabs>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }}
                            className={this.state.activeTab === '1' ? 'active' : 'inactive'}
                            onClick={() => this.toggleTab('1')}
                        >
                            Create Ladders
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }}
                            className={this.state.activeTab === '2' ? 'active' : 'inactive'}
                            onClick={() => this.toggleTab('2')}
                        >
                            View Ladders
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane className={this.state.activeTab === '1' ? 'tab-pane-active' : ''} tabId="1">
                        <div className="ladder-container">
                        <br />
                            <Row>
                                <div className="col-lg-12"><b>Note: </b>You can only create ladders for playgroups you are the host of, but you can view ladders from all playgroups you are a part of.</div>
                                {this.state.errMsg !== "" ? <div style={{ color: "red" }}>{this.state.errMsg}</div> : ""}
                            </Row>
                            <Row>
                                <Col style={{ padding: "15px" }} className="col-lg-12">
                                    
                                    
                                    <br />
                                    <Label for="playgroupSelect"><b>Select playgroup</b></Label>
                                    <Dropdown isOpen={this.state.dropdownPlayers} toggle={() => this.toggleDropdown('dropdownPlayers')}>
                                        <DropdownToggle caret>
                                            {selectedPlaygroup ? selectedPlaygroup.playgroupName : 'Select a playgroup'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.filteredPlaygroups.map((playgroup) => (
                                                <DropdownItem
                                                    key={playgroup.id}
                                                    onClick={() => this.handlePlaygroupSelect(playgroup)}
                                                >
                                                    {playgroup.playgroupName}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>

                                <Col style={{ padding: "15px" }} className="col-lg-12">
                                    <Label><b>Select game type</b></Label>
                                    <Dropdown isOpen={this.state.dropdownGameTypes} toggle={() => this.toggleDropdown('dropdownGameTypes')}>
                                        <DropdownToggle caret>
                                            {this.state.selectedGameType ? this.state.selectedGameType.gameType : 'Select a game type'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.gameTypes.map((gameType) => (
                                                <DropdownItem
                                                    key={gameType.id}
                                                    onClick={() => this.handleGameTypeSelect(gameType)}
                                                >
                                                    {gameType.gameType}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>

                                <Col style={{ padding: "15px" }} className="col-lg-12">
                                    <Label for="ladderName"><b>Ladder Name</b></Label>
                                    <Input
                                        type="text"
                                        id="ladderName"
                                        value={ladderName}
                                        onChange={(e) => this.setState({ ladderName: e.target.value })}
                                        maxLength={30}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col style={{ padding: "15px" }} className="col-lg-12">
                                    <Label><b>Enter a date range (All time by default)</b></Label><br />
                                    <DatePicker
                                        selected={startDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(dates) => {
                                            const [start, end] = dates;
                                            this.setState({ startDate: start, endDate: end });
                                        }}
                                        selectsRange
                                        className="form-control"
                                    />
                                </Col>

                               
                            </Row>

                            <Row>
                                <Col style={{ padding: "15px" }}>
                                    <Label for="playerList"><b>Select players to participate in this ladder</b></Label>
                                    <div style={{ height: '300px', overflowY: 'auto', border: '1px solid #ccc' }}>
                                        <div style={{padding:"5px"}} onClick={() => this.handlePlayerClick('selectAll')}>
                                            <input type="checkbox"  checked={this.state.selectAll} readOnly /> &nbsp;
                                            Select All
                                        </div>
                                        {playerList.map((player) => (
                                            <div style={{ padding: "5px" }} key={player.id} onClick={() => this.handlePlayerClick(player.accountId)}>
                                                <input type="checkbox" checked={player.selected} readOnly /> {player.username}
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col style={{ padding: "15px" }} xs="12">
                                    <Button className="btn btn-primary" onClick={this.handleCreateLadder}>Create Ladder</Button>
                                </Col>
                            </Row>
                        </div>
                   

                    </TabPane>
                    <TabPane className={this.state.activeTab === '2' ? 'tab-pane-active' : ''} tabId="2">
                        <ViewLadder gameTypes={this.state.gameTypes} accountId={this.state.accountId} playgroups={this.state.playgroups}></ViewLadder>
                    </TabPane>
                </TabContent>

               


           
            </Layout>
        );
    }
}
    


export default Ladders;
