import React, { Component } from 'react';
import getApiData, { postApiData } from '../fetchApi';
import CheckoutForm from './CheckoutForm'
import { Layout } from './Layout';

class Premium extends Component {

    constructor(props) {
        
        super(props);
        this.state = {
            isPremium: 0,
            cancelMsg: "",
            end: ""
        };
    }

    componentDidMount = async () =>
    {
        var end = await getApiData("IS_CANCEL_PREMIUM", [sessionStorage.getItem("x-params")])
        const parts = window.location.href.split('/');

        if (parts[parts.length - 3] === "success") {
            
            await postApiData("SET_PREMIUM", [parts[parts.length - 2]])
            this.setState({
                message: "Thank you for your subscription.",
            });
        }

        if (parts[parts.length - 3] === "cancel") {
            await postApiData("CANCEL_CHECKOUT", [parts[parts.length - 2]])
            this.setState({
                message:
                    "Subscription payment canceled",
            });
        }

        var isPremium = await getApiData("GET_IS_PREMIUM", [sessionStorage.getItem("x-params")])
        this.setState({isPremium: isPremium, end: end})
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    cancelSubscription = async () => {
        var end = await postApiData("CANCEL_PREMIUM", [sessionStorage.getItem("x-params")])
        this.setState({cancelMsg: "Your subscription has been canceled", end: end})
    }

    render() {
        return (
            <>
                <Layout showNavMenu={true}>
                    {this.state.isPremium === 0 ?
                        <>
                             <div>
                                <h3>Sign up for Premium</h3>
                                <p>
                                    Please consider signing up for premium to receive some benefits for your account and help support the app.
                                    All proceeds go towards continued development as well as server costs.
                                </p>

                                <p>TCGLadder premium subscription is $2.99/month and grants your account the following benefits:</p>

                                <ul>
                                    <li>Removes all ads (currently there are no ads, I am pending approval)</li>
                                    <li>Uncaps the number of playgroups you can be a part of from 4 to 12</li>
                                    <li>Uncaps total player limit in a match from 4 to 10</li>
                                    <li>Uncaps the number of ladders you can create per playgroup from 4 to 12</li>
                                </ul>
               
                            </div>
                       
               
                            <CheckoutForm />
                        </>
                        :
                        <>
                        <div>
                                {this.state.end === "" ?
                                    <>
                                        <h3>Thank you for signing up!</h3>
                                        <p>
                                            Your generosity goes a long way. Thank you for supporting the continued development of TCGLadder as well as helping to pay for server costs.
                                        </p>

                                        <p>You may cancel your subscription at anytime.</p>

                                        <button className="btn btn-danger" onClick={this.cancelSubscription}>Cancel Subscription</button>
                                        <br />
                                        <div>{this.state.cancelMsg}</div>
                                        <br />
                                    </>
                                    :
                                    <>
                                        <h3>Your subscription has been canceled</h3> <br />
                                        <div> Your premium benefits will end on {this.state.end.split(' ')[0]}</div>
                                    </>}
                                    
                        </div>

                        </>
            
                    }
                
                </Layout>
            </>
        );
    }
}

export default Premium;
