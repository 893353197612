import  Stats  from "./components/Stats";
import  DeckMenu  from "./components/DeckMenu";
import  Home  from "./components/Home";
import Login from "./components/Login";
import Ladder from "./components/Ladders";
import History from "./components/MatchHistory";
import LandingPage from "./components/LandingPage";
import Guide from "./components/Guide"
import Verify from "./components/Verify"
import Privacy from "./components/PrivacyPolicy"
import Premium from "./components/Premium"
import Forgot from "./components/Forgot"

const AppRoutes = [
    {
        index:true,
        element: <LandingPage />
    },
    {
        path: '/login',
    element: <Login />
    },
    {
        path: '/forgot/:guid',
        element: <Forgot />
    },
    {
        path: '/premium',
        element: <Premium />
    },
    {
        path: '/premium/success/:guid/',
        element: <Premium />
    },
    {
        path: '/premium/cancel/:guid/',
        element: <Premium />
    },
    {
        path: '/verify/:guid',
        element: <Verify />
    },
    {
        path: '/privacy-policy',
        element: <Privacy />
    },
    {
        path: '/home',
        element: <Home />
    },
     {
      path: '/stats',
    element: <Stats />
    },
    {
        path: '/deckmenu',
        element: <DeckMenu />
    },
    {
        path: '/history',
        element: <History />
    },
    {
        path: '/ladder',
        element: <Ladder />
    },
    {
        path: '/how-to',
        element: <Guide />
    },
  
];

export default AppRoutes;
